import { useEffect, useRef, useState } from "react";
import { Product } from "../../Models/Product";
import { Navbar } from "../../Components/Navbar/Navbar";
import { STOCK_ALL_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import "./StockAll.scss";
import { ThreeCircles } from "react-loader-spinner";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useProduct from "../../hooks/useProduct";
import { API_LIMIT } from "../../Constants/ENUMS";

export const StockAll = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(1);
  const [onlyWarnings, setOnlyWarnings] = useState<boolean>(false);

  const { productsLoading, getAllProducts } = useProduct();

  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (searchTerm.length === 0) {
      getAllProducts(pageCount, onlyWarnings ? 500 : API_LIMIT, searchTerm, onlyWarnings ? 1 : 0)
        .then((result) => {
          if (pageCount === 1) {
            setProducts(result);
          } else {
            setProducts(products.concat(result));
          }
        })
        .catch((err) => console.error(err));
    }
    if (searchTerm.length > 2) {
      if (timerRef.current) window.clearTimeout(timerRef.current);

      timerRef.current = window.setTimeout(() => {
        getAllProducts(pageCount, API_LIMIT, searchTerm, onlyWarnings ? 1 : 0)
          .then((result) => {
            if (pageCount === 1) {
              setProducts(result);
            } else {
              setProducts(products.concat(result));
            }
          })
          .catch((err) => console.error(err));
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, searchTerm, onlyWarnings]);

  return (
    <div>
      <Navbar />

      <div className="StockWrapper">
        <div className="stockHeaderWrapper">
          <h1 className="stockHeader">{STOCK_ALL_ROUTE.TITLE}</h1>
        </div>

        <div className="searchLoaderWrapperStock">
          <div className="group">
            <svg viewBox="0 0 24 24" fill="white" aria-hidden="true" className="searchIcon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              onChange={(e) => {
                setSearchTerm(e.target.value.toUpperCase());
                setPageCount(1);
              }}
              className="input"
              type="text"
              placeholder="Ara..."
            />
          </div>
          <button
            onClick={() => {
              setOnlyWarnings(!onlyWarnings);
              setPageCount(1);
            }}
            className={onlyWarnings ? "warningButton" : "inActiveWarningButton"}
          >
            Sadece Uyarıları Göster
          </button>{" "}
          {productsLoading ? (
            <div>
              <ThreeCircles height="60" width="60" color="var(--primary-color)" />
            </div>
          ) : null}
        </div>

        <div className="stockAllTableWrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>Parça Tanımı</th>
                <th>Raf</th>
                <th>Mevcut Adet</th>
                <th>Birim Liste Fiyatı</th>
                <th>Not</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => {
                return product.productEntries
                  .filter((entry) => entry.show)
                  .map((entry) => {
                    return (
                      <tr
                        style={{
                          backgroundColor: product.minAmount && product.minAmount > product.productEntries.reduce((a, b) => a + b.currentAmount, 0) ? "yellow" : "white",
                        }}
                        key={entry._prodEntryId}
                      >
                        <td className="productCodeTD">
                          {product.minAmount && product.minAmount > product.productEntries.reduce((a, b) => a + b.currentAmount, 0) ? (
                            <>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 20 20"
                                fill="none"
                                data-tooltip-id={`stockInfoAmountTooltip-${product._productId}`}
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0001 7.00005C11.0001 7.55233 10.5523 8.00005 10.0001 8.00005C9.44778 8.00005 9.00006 7.55233 9.00006 7.00005C9.00006 6.44776 9.44778 6.00005 10.0001 6.00005C10.5523 6.00005 11.0001 6.44776 11.0001 7.00005Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.0001 9.75005C10.4143 9.75005 10.7501 10.0858 10.7501 10.5V15.5C10.7501 15.9143 10.4143 16.25 10.0001 16.25C9.58585 16.25 9.25006 15.9143 9.25006 15.5V10.5C9.25006 10.0858 9.58585 9.75005 10.0001 9.75005Z"
                                  fill="black"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12.2704 1.99268C11.1784 0.39502 8.82165 0.395014 7.72967 1.99268L7.29775 2.62463C4.59171 6.58384 2.26675 10.7905 0.354018 15.1881L0.263803 15.3956C-0.410252 16.9453 0.608112 18.705 2.28766 18.8927C7.41329 19.4656 12.5868 19.4656 17.7124 18.8927C19.392 18.705 20.4103 16.9453 19.7363 15.3956L19.6461 15.1881C17.7333 10.7905 15.4084 6.58385 12.7023 2.62463L12.2704 1.99268ZM8.96806 2.83909C9.46441 2.11288 10.5357 2.11288 11.032 2.83909L11.4639 3.47104C14.1165 7.35206 16.3956 11.4756 18.2705 15.7864L18.3608 15.9938C18.6322 16.6179 18.2221 17.3264 17.5458 17.402C12.5309 17.9625 7.46917 17.9625 2.45427 17.402C1.77797 17.3264 1.36791 16.6179 1.63933 15.9938L1.72954 15.7864C3.6045 11.4756 5.88354 7.35205 8.53613 3.47104L8.96806 2.83909Z"
                                  fill="black"
                                />
                              </svg>
                              <ReactTooltip
                                style={{ color: "black", zIndex: 5, width: "15rem", whiteSpace: "normal", wordBreak: "break-word" }}
                                variant="warning"
                                id={`stockInfoAmountTooltip-${product._productId}`}
                                place="bottom"
                                content={`Ürünün girişlerinin toplam anlık adedi (${product.productEntries.reduce((a, b) => a + b.currentAmount, 0)}) minimum adedinden (${
                                  product.minAmount
                                }) az!`}
                              />
                            </>
                          ) : null}
                          {`${product.code}:${product.name}`.toUpperCase()}
                        </td>
                        <td>{entry.shelf ? `${entry.shelf}`.toUpperCase() : product.shelf ? `${product.shelf}`.toUpperCase() : ""}</td>
                        <td>{entry.currentAmount}</td>
                        <td>{entry.pricePerAmount}</td>
                        <td className="truncatedTD" data-tooltip-id={`NoteTooltip-${product._productId}`}>
                          {product.note}
                        </td>
                        <ReactTooltip
                          style={{ color: "black", zIndex: 5, width: "15rem", whiteSpace: "normal", fontSize: "1rem", wordBreak: "break-word" }}
                          variant="info"
                          id={`NoteTooltip-${product._productId}`}
                          place="bottom"
                          content={product.note}
                        />
                      </tr>
                    );
                  });
              })}
            </tbody>
          </table>
          <button onClick={() => setPageCount(pageCount + 1)} className="loadMoreButton">
            Daha fazla yükle
          </button>
        </div>
      </div>
    </div>
  );
};
