import React, { useState } from "react";
import { Customer } from "../../../Models/Customer";
import "./AddCustomerPopUp.scss";

interface IAddCustomerPopUp {
  createdCustomer?: (customer: Customer) => void;
  editedCustomer?: (customer: Customer) => void;
  closed: (closed: boolean) => void;
  customer?: Customer;
}

export const AddCustomerPopUp: React.FC<IAddCustomerPopUp> = ({ createdCustomer, editedCustomer, closed, customer }) => {
  const initState = (): Customer => {
    if (customer) {
      return customer;
    } else {
      return {
        _customerId: 0,
        name: "",
        address: "",
        taxId: "",
        email: "",
        machines: [],
        defaultPercentages: {
          piecePercentage: 18,
          workerPercentage: 18,
          KDVPercentage: 16,
        },
        officials: [
          { name: "", phoneNumber: 0 },
          { name: "", phoneNumber: 0 },
        ],
        paymentInfo: {
          limit: 0,
          amountSpent: 0,
          amountReimbursed: 0,
        },
      };
    }
  };
  const [customerForm, setCustomerForm] = useState<Customer>(initState());

  return (
    <div className="AddPopUpBg">
      <div className="AddCustomerPopUpWrapper">
        <div className="AddPopUpHeader">Müşteri Bilgilerini Giriniz</div>

        <div className="AddCustomerPopUpContent">
          <div className="AddCustomerContent">
            İsim:
            <input
              className="CustomerInput"
              defaultValue={customerForm.name}
              placeholder="İsim giriniz..."
              type="text"
              onChange={(e) => setCustomerForm({ ...customerForm, name: e.target.value })}
            />
            Adres:{" "}
            <input
              className="CustomerInput"
              defaultValue={customerForm.address}
              placeholder="Adres giriniz..."
              type="text"
              onChange={(e) => setCustomerForm({ ...customerForm, address: e.target.value })}
            />
            Mail:{" "}
            <input
              className="CustomerInput"
              defaultValue={customerForm.email}
              placeholder="Mail giriniz..."
              type="text"
              onChange={(e) => setCustomerForm({ ...customerForm, email: e.target.value })}
            />
            Yetkili 1:
            <input
              className="CustomerInput"
              defaultValue={customerForm.officials[0].name}
              placeholder="Yetkili İsmi giriniz..."
              type="text"
              onChange={(e) => setCustomerForm({ ...customerForm, officials: [{ ...customerForm.officials[0], name: e.target.value }, { ...customerForm.officials[1] }] })}
            />
            <input
              className="CustomerInput"
              defaultValue={customerForm.officials[0].phoneNumber}
              placeholder="Yetkili Numarası giriniz..."
              type="number"
              onChange={(e) =>
                setCustomerForm({ ...customerForm, officials: [{ ...customerForm.officials[0], phoneNumber: parseInt(e.target.value) }, { ...customerForm.officials[1] }] })
              }
            />
            Yetkili 2 - (Opsiyonel):
            <input
              className="CustomerInput"
              defaultValue={customerForm.officials[1] ? customer?.officials[1].name : undefined}
              placeholder="Yetkili İsmi giriniz..."
              type="text"
              onChange={(e) => setCustomerForm({ ...customerForm, officials: [{ ...customerForm.officials[0] }, { ...customerForm.officials[1], name: e.target.value }] })}
            />
            <input
              className="CustomerInput"
              defaultValue={customerForm.officials[1] ? customer?.officials[1].phoneNumber : undefined}
              placeholder="Yetkili Numarası giriniz..."
              onChange={(e) =>
                setCustomerForm({ ...customerForm, officials: [{ ...customerForm.officials[0] }, { ...customerForm.officials[1], phoneNumber: parseInt(e.target.value) }] })
              }
            />
          </div>
          <div style={{ borderLeft: "1px solid #DBDBDB" }} className="AddCustomerContent">
            Vergi Numarası:
            <input
              className="CustomerInput"
              defaultValue={customerForm.taxId}
              placeholder="Vergi Numarası giriniz..."
              type="text"
              onChange={(e) => setCustomerForm({ ...customerForm, taxId: e.target.value })}
            />
            Harcama Limiti:
            <input
              className="CustomerInput"
              defaultValue={customerForm.paymentInfo.limit}
              placeholder="Limit giriniz..."
              type="number"
              onChange={(e) =>
                setCustomerForm({
                  ...customerForm,
                  paymentInfo: { amountSpent: customerForm.paymentInfo.amountSpent, amountReimbursed: customerForm.paymentInfo.amountReimbursed, limit: parseInt(e.target.value) },
                })
              }
            />
            Parça İndirimi:
            <input
              className="CustomerInput"
              defaultValue={customerForm.defaultPercentages.piecePercentage}
              placeholder="Parça İndirimi giriniz..."
              type="number"
              onChange={(e) =>
                setCustomerForm({
                  ...customerForm,
                  defaultPercentages: { ...customerForm.defaultPercentages, piecePercentage: parseInt(e.target.value) },
                })
              }
            />
            İşçilik İndirimi:
            <input
              className="CustomerInput"
              defaultValue={customerForm.defaultPercentages.workerPercentage}
              placeholder="İşçilik İndirimi giriniz..."
              type="number"
              onChange={(e) =>
                setCustomerForm({
                  ...customerForm,
                  defaultPercentages: { ...customerForm.defaultPercentages, workerPercentage: parseInt(e.target.value) },
                })
              }
            />
            KDV:
            <input
              className="CustomerInput"
              defaultValue={customerForm.defaultPercentages.KDVPercentage}
              placeholder="KDV giriniz..."
              type="number"
              onChange={(e) =>
                setCustomerForm({
                  ...customerForm,
                  defaultPercentages: { ...customerForm.defaultPercentages, KDVPercentage: parseInt(e.target.value) },
                })
              }
            />
          </div>
        </div>
        <span className="buttonWrapper">
          <button
            disabled={
              customerForm.address === "" ||
              customerForm.email === "" ||
              customerForm.name === "" ||
              customerForm.officials[0].name === "" ||
              customerForm.officials[0].phoneNumber === 0 ||
              Number.isNaN(customerForm.officials[0].phoneNumber) ||
              customerForm.taxId === "" ||
              isNaN(customerForm.defaultPercentages.piecePercentage) ||
              isNaN(customerForm.defaultPercentages.workerPercentage) ||
              isNaN(customerForm.defaultPercentages.KDVPercentage)
            }
            className="addBtn"
            onClick={() => {
              closed(true);
              if (createdCustomer) {
                createdCustomer(customerForm);
              }
              if (editedCustomer) {
                editedCustomer(customerForm);
              }
            }}
          >
            {createdCustomer ? <>Müşteri Ekle</> : <>Değişiklikleri Kaydet</>}
          </button>
          <button
            className="cancelBtn"
            onClick={() => {
              closed(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
