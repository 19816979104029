import React from "react";
import { Customer } from "../../Models/Customer";
import "./CustomerInfo.scss";

interface ICustomerInfo {
  customer: Customer;
}

export const CustomerInfo: React.FC<ICustomerInfo> = ({ customer }) => {
  return (
    <div>
      <div className="container">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-1">Adres</div>
            <div className="col col-2">Mail</div>
            <div className="col col-3">Vergi Numarası</div>
            <div className="col col-4">Yetkili İsmi</div>
            <div className="col col-4">Yetkili Numarası</div>
            {customer.officials[1].phoneNumber !== 0 || customer.officials[1].name !== "" ? <div className="col col-4">Yetkili İsmi</div> : null}
            {customer.officials[1].phoneNumber !== 0 || customer.officials[1].name !== "" ? <div className="col col-4">Yetkili Numarası</div> : null}
            <div className="col col-4">Cari</div>
            <div className="col col-4">Limit</div>
          </li>
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
              {customer.address}
            </div>
            <div className="col col-2" data-label="Customer Name">
              {customer.email}
            </div>
            <div className="col col-3" data-label="Amount">
              {customer.taxId}
            </div>
            <div className="col col-4" data-label="Payment Status">
              {customer.officials[0].name}
            </div>
            <div className="col col-4" data-label="Payment Status">
              {customer.officials[0].phoneNumber}
            </div>
            {customer.officials[1].phoneNumber !== 0 || customer.officials[1].name !== "" ? (
              <div className="col col-4" data-label="Payment Status">
                {customer.officials[1].name}
              </div>
            ) : null}
            {customer.officials[1].phoneNumber !== 0 || customer.officials[1].name !== "" ? (
              <div className="col col-4" data-label="Payment Status">
                {customer.officials[1].phoneNumber}
              </div>
            ) : null}
            <div className="col col-4" data-label="Payment Status">
              {customer.paymentInfo.limit}
            </div>
            <div className="col col-4" data-label="Payment Status">
              %{customer.paymentInfo.limit === 0 ? 0 : Math.round((customer.paymentInfo.amountSpent * 100) / customer.paymentInfo.limit)}
            </div>
          </li>
        </ul>
      </div>
      {/*   <div className="InfoWrapper">
        <div className="customerInfoWrapper">
          <div className="addressTax">
            <div className="customerInfoItem">
              <div className="customerInfoHeader">Adres:</div>
              <div>{customer.address}</div>
            </div>
            <div className="customerInfoItem">
              <div className="customerInfoHeader">Mail:</div>
              <div>{customer.email}</div>
            </div>
            <div className="customerInfoItem">
              <div className="customerInfoHeader">Vergi:</div>
              <div>{customer.taxId}</div>
            </div>
          </div>
        </div>
        <span>
          {customer.officials.map((official, index) => {
            return (
              <div key={index}>
                <div className="customerInfoItem">
                  <div className="customerInfoHeader">Yetkili:</div>
                  <div>{official.name}</div>
                </div>
                <div className="customerInfoItem">
                  <div className="customerInfoHeader">Yetkili Numarası:</div>
                  <div>{official.phoneNumber}</div>
                </div>
              </div>
            );
          })}
        </span>
        <span>
          <div className="customerInfoItem">
            <div className="customerInfoHeader">Cari:</div>
            <div>{customer.paymentInfo.limit}</div>
          </div>
          <div className="customerInfoItem">
            <div className="customerInfoHeader">Limit:</div>
            <div>%{Math.round((customer.paymentInfo.amountSpent * 100) / customer.paymentInfo.limit)}</div>
          </div>
        </span>
      </div> */}
    </div>
  );
};
