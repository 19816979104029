import React, { useMemo } from "react";
import { LOGIN_ROUTE } from "../Constants/ROUTE_PATH_TITLES";
import { User } from "../Models/User";
import { Navigate } from "react-router";
import { jwtDecode } from "jwt-decode";

interface IProtectedRoute {
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ children }) => {
  const token = JSON.parse(localStorage.getItem("token") || "{}");

  const isTokenValid = useMemo(() => {
    if (Object.keys(token).length !== 0) {
      const tokenDecoded = jwtDecode(token);
      if (tokenDecoded.exp) {
        const tokenExpDate = new Date(tokenDecoded.exp * 1000);
        const currentDate = new Date();
        if (tokenExpDate.getTime() - currentDate.getTime() > 0) {
          return true;
        }
      }
    }
    return false;
  }, [token]);

  if (isTokenValid) {
    return <>{children}</>;
  } else {
    return <Navigate to={LOGIN_ROUTE.PATH} replace />;
  }
};
