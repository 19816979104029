import { combineReducers } from "redux";

import { User } from "../../Models/User";
import UserReducer from "./UserReducer";
import CurrencyReducer from "./CurrencyReducer";
import { Currency } from "../../Models/Currency";
import DashboardSelectionsReducer from "./DashboardSelectionsReducer";
import DefaultWorkPriceReducer from "./DefaultWorkPriceReducer";

export type GlobalState = {
  user: User;
  currency: Currency;
  selections: string[];
  defaultWorkPrice: number;
};

const rootReducer = combineReducers<GlobalState>({
  user: UserReducer,
  currency: CurrencyReducer,
  selections: DashboardSelectionsReducer,
  defaultWorkPrice: DefaultWorkPriceReducer,
});

export default rootReducer;
