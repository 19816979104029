import React, { useState } from "react";
import { Product } from "../../../Models/Product";
import "./AddProductPopUp.scss";

interface IAddProductPopUp {
  createdProduct?: (product: Product) => void;
  editedProduct?: (product: Product) => void;
  currentProduct?: Product;
  close: (closed: boolean) => void;
}

export const AddProductPopUp: React.FC<IAddProductPopUp> = ({ createdProduct, close, editedProduct, currentProduct }) => {
  const initState = (): Product => {
    if (currentProduct) {
      return currentProduct;
    } else {
      return {
        _productId: 0,
        pricePerProduct: 0,
        name: "",
        amount: 0,
        code: "",
        brand: "",
        productEntries: [],
        note: "",
      };
    }
  };

  const [initProduct, setInitProduct] = useState<Product>(initState());

  return (
    <div className="AddProductPopUpBg">
      <div className="AddProductPopUpWrapper">
        <div className="AddPopUpHeader">Ürün Bilgilerini Giriniz</div>

        <div className="AddProductPopUpContent">
          Firma - Marka:{" "}
          <input
            className="ProductInput"
            defaultValue={initProduct.brand}
            placeholder="Marka giriniz..."
            type="text"
            onChange={(e) => setInitProduct({ ...initProduct, brand: e.target.value })}
          />
          Kod:{" "}
          <input
            className="ProductInput"
            defaultValue={initProduct.code}
            placeholder="Kod giriniz..."
            type="text"
            onChange={(e) => setInitProduct({ ...initProduct, code: e.target.value })}
          />
          İsim:{" "}
          <input
            className="ProductInput"
            defaultValue={initProduct.name}
            placeholder="İsim giriniz..."
            type="text"
            onChange={(e) => setInitProduct({ ...initProduct, name: e.target.value })}
          />
          Not:{" "}
          <textarea
            className="ProductInputNote"
            contentEditable={true}
            defaultValue={initProduct.note}
            placeholder="Not giriniz..."
            onChange={(e) => setInitProduct({ ...initProduct, note: e.target.value })}
          />
          Raf - (Opsiyonel):{" "}
          <input
            defaultValue={initProduct.shelf}
            className="ProductInput"
            placeholder="Raf giriniz..."
            type="text"
            onChange={(e) => setInitProduct({ ...initProduct, shelf: e.target.value })}
          />
          Minimum Adet - (Opsiyonel):
          <input
            className="ProductInput"
            defaultValue={initProduct.minAmount}
            placeholder="Adet giriniz..."
            type="number"
            onChange={(e) => setInitProduct({ ...initProduct, minAmount: parseInt(e.target.value) })}
          />
        </div>
        <h4 style={{ width: "100%", textAlign: "center" }}>Hatırlatma: Ürüne Ekleme Yapmak için stok sayfasında "Seçili Ürüne Ekleme Yap" butonunu kullanın</h4>
        <span className="buttonWrapper">
          <button
            disabled={initProduct.brand === "" || initProduct.name === "" || initProduct.code === ""}
            className="addBtn"
            onClick={() => {
              close(true);

              if (createdProduct) {
                createdProduct(initProduct);
              }
              if (editedProduct) {
                editedProduct(initProduct);
              }
            }}
          >
            {createdProduct ? <>Ürün Ekle</> : <>Ürünü Düzenle</>}
          </button>
          <button
            className="cancelBtn"
            onClick={() => {
              close(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
