import { useState } from "react";
import ReactSelect, { MultiValue, SingleValue } from "react-select";
import { CustomerInfo } from "../CustomerInfo/CustomerInfo";
import { Customer } from "../../Models/Customer";
import { AddCustomerPopUp } from "../PopUps/AddCustomerPopUp/AddCustomerPopUp";
import classes from "./CustomerWrapper.module.scss";
import { Machines } from "../Machines/Machines";
import { ThreeCircles } from "react-loader-spinner";
import { HOME_ROUTE, WORK_ORDER_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import { useNavigate } from "react-router";
import { DeleteCustomerPopUp } from "../PopUps/DeleteCustomerPopUp/DeleteCustomerPopUp";
import { Currency } from "../../Models/Currency";

export type RSelectOption = {
  value: Customer;
  label: string;
};

interface ICustomerWrapper {
  customers: Customer[];
  updateDefaultWorkPrice: (updatedPrice: number) => void;
  customerSearch: (searchTerm: string) => void;
  hookedDefaultWorkPrice: number;
  currency: Currency;
  isParentLoading?: boolean;
  updateCustomer: (customer: Customer) => void;
  deleteCustomer: (customer: Customer) => void;
  addCustomer: (customer: Customer) => void;
}

export const CustomerWrapper: React.FC<ICustomerWrapper> = ({
  customers,
  currency,
  isParentLoading,
  hookedDefaultWorkPrice,
  updateDefaultWorkPrice,
  customerSearch,
  updateCustomer,
  deleteCustomer,
  addCustomer,
}) => {
  const [selectedOption, setSelectedOptions] = useState<RSelectOption>();
  const [priceToBeUpdated, setPriceToBeUpdated] = useState<number>(hookedDefaultWorkPrice);

  const [addCustomerClicked, setAddCustomerClicked] = useState<boolean>(false);
  const [updateClicked, setUpdateClicked] = useState<boolean>(false);
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

  const handleSelectedOptionChange = (newSelectedOption: MultiValue<RSelectOption> | SingleValue<RSelectOption>) => {
    setSelectedOptions(newSelectedOption as RSelectOption);
  };

  const navigate = useNavigate();

  console.log(selectedOption);

  /* useEffect(() => {
    setSelectedOptions(undefined);
  }, [customers]);
 */

  return (
    <div className={classes.CustomerWrapper}>
      <div className={classes.homeHeaderWrapper}>
        <h1 className={classes.homeHeader}>{HOME_ROUTE.TITLE}</h1>
      </div>

      {addCustomerClicked ? <AddCustomerPopUp closed={(closed) => setAddCustomerClicked(!closed)} createdCustomer={addCustomer} /> : null}
      {updateClicked ? (
        <AddCustomerPopUp
          closed={(closed) => setUpdateClicked(!closed)}
          editedCustomer={(customer) => {
            updateCustomer(customer);
            if (selectedOption) setSelectedOptions({ value: customer, label: selectedOption?.label });
          }}
          customer={selectedOption?.value}
        />
      ) : null}
      {deleteClicked ? (
        <DeleteCustomerPopUp
          onClick={() => {
            if (selectedOption?.value) {
              deleteCustomer(selectedOption?.value);
            }
          }}
          close={(closed) => {
            setDeleteClicked(!closed);
          }}
        />
      ) : null}
      <div className={classes.dropdownButtonWrapper}>
        <div className={classes.dpBtnWrppr}>
          <ReactSelect
            className={classes.customerDropdown}
            options={customers.map((customer) => {
              return { value: customer, label: customer.name.toUpperCase() };
            })}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "var(--secondary-color)",
                primary: "var(--primary-color)",
              },
            })}
            noOptionsMessage={() => {
              if (isParentLoading) {
                return "Yükleniyor...";
              }
              return "Müşteri bulunamadı";
            }}
            onInputChange={(e) => customerSearch(e)}
            onChange={handleSelectedOptionChange}
            placeholder="Müşteri aramak için en az 3 karakter giriniz..."
          />
          <button className={classes.addButton} onClick={() => setAddCustomerClicked(true)}>
            Müşteri ekle
          </button>
          <button disabled={selectedOption?.value === undefined} className={classes.deleteCustomer} onClick={() => setDeleteClicked(true)}>
            Seçili müşteriyi sil
          </button>
          <button
            disabled={selectedOption?.value === undefined}
            style={{ backgroundColor: "var(--secondary-color)" }}
            className={classes.addButton}
            onClick={() => setUpdateClicked(true)}
          >
            Müşteri Düzenle
          </button>
        </div>
        {isParentLoading ? null : (
          <span className={classes.homeCurrencyWrapper}>
            <span>€ : {Math.round(currency.rate * 10000) / 10000}</span>
            <span>£ : {Math.round(currency.ratePound * 10000) / 10000}</span>
            <span>$ : {Math.round(currency.rateUSD * 10000) / 10000}</span>
          </span>
        )}
        <div className={classes.defaultWorkWrapper}>
          <button className={classes.addButton} onClick={() => updateDefaultWorkPrice(priceToBeUpdated)} disabled={priceToBeUpdated <= 0 || priceToBeUpdated.toString() === "NaN"}>
            Varsayılan İşçilik Ücretini Düzenle
          </button>
          <input
            type="number"
            min={1}
            placeholder={hookedDefaultWorkPrice.toString()}
            onChange={(e) => {
              setPriceToBeUpdated(parseInt(e.target.value));
            }}
          />
        </div>

        {selectedOption !== undefined ? (
          <div className={classes.customerBtnWrapper}>
            <button
              style={{ marginRight: "1rem" }}
              onClick={() => navigate(`${WORK_ORDER_ROUTE.PATH}/${selectedOption.value._customerId}/WorkOrders/0`)}
              className={classes.cssbuttons_io_button}
            >
              İş Emirleri: {selectedOption.value.name}
              <div className={classes.icon}>
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
                </svg>
              </div>
            </button>
            <button
              style={{ marginLeft: "1rem" }}
              onClick={() => navigate(`${WORK_ORDER_ROUTE.PATH}/${selectedOption.value._customerId}/Bills/0`)}
              className={classes.cssbuttons_io_button}
            >
              Faturalar: {selectedOption.value.name}
              <div className={classes.icon}>
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
                </svg>
              </div>
            </button>
          </div>
        ) : null}
      </div>
      {selectedOption !== undefined ? (
        <div className={classes.infoAndButtonWrapper}>
          <CustomerInfo customer={selectedOption.value} />
        </div>
      ) : null}
      <div className={classes.machines}>
        {selectedOption !== undefined ? (
          <Machines
            handleCreatedMachine={(customer) => {
              updateCustomer(customer);
              setSelectedOptions({ value: customer, label: selectedOption?.label });
            }}
            customer={selectedOption.value}
          />
        ) : null}
      </div>
      {selectedOption === undefined ? (
        <div className={classes.gitLinks}>
          Developed by <a href="https://github.com/iamnora">İtibar Göksu Özkan</a> and <a href="https://github.com/PigeonTheDev">Ulaş Ayçiçek</a>
        </div>
      ) : null}
      {isParentLoading ? (
        <div className={classes.LoaderWrapper}>
          <ThreeCircles height="80" width="80" color="var(--primary-color)" />
        </div>
      ) : null}
    </div>
  );
};
