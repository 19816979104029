import { Bar, BarChart, CartesianGrid, Cell, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import { Navbar } from "../../Components/Navbar/Navbar";
import { DASHBOARD_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import { Statistics } from "../../Models/Statistics";
import "./Dashboard.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import API from "../../api/API";
import { ThreeCircles } from "react-loader-spinner";
import { ExportBillPopUp } from "../../Components/PopUps/ExportBillPopUp/ExportBillPopUp";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/reducers";
import { CHANGE_SELECTION } from "../../Redux/DashboardSelectionsActions";

/* const mockData: Statistics = {
  WorkOrderStatistics: [
    {
      boughtPriceAmountTimesAmount: 0,
      customsTimesAmount: 0,
      KKTC_KDVTimesAmount: 0,
      total: 0,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 10,
      customsTimesAmount: 50,
      KKTC_KDVTimesAmount: 110,
      total: 170,
    },
    {
      boughtPriceAmountTimesAmount: 120,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 0,
      total: 150,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
    {
      boughtPriceAmountTimesAmount: 20,
      customsTimesAmount: 30,
      KKTC_KDVTimesAmount: 10,
      total: 60,
    },
  ],
  productEntryStatistics: [
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
    { boughtPriceAmount: 100, customs: 90, KKTC_KDV: 80, total: 270 },
  ],
  SellingStatistics: [
    { workDone: 0, productsSold: 0, KDV: 0, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
    { workDone: 100, productsSold: 120, KDV: 20, totalSell: 240 },
  ],
};
 */

function getMonthName(monthNumber: number) {
  const date = new Date(2024, monthNumber, 10);

  return date.toLocaleString("tr", { month: "long" }).slice(0, 3);
}

const currentDate = new Date();

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const api = new API();
export const Dashboard = () => {
  const [statisticsData, setStatisticsData] = useState<Statistics>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [renderedWorkOrderLineKeys, setRenderedWorkOrderLineKeys] = useState<{ name: string; color: string; header: string }[]>([
    { name: "boughtPriceAmountTimesAmount", color: "#6a0dad", header: "Parça Bedeli" },
    { name: "customsTimesAmount", color: "#cc0000", header: "Gümrük ve Diğer Masraf" },
    { name: "KKTC_KDVTimesAmount", color: "#487c90", header: "KDV Tutarı" },
    { name: "total", color: "#89a223", header: "Toplam Alım" },
    { name: "workDone", color: "#77533a", header: "Satış İşçilik" },
    { name: "productsSold", color: "#628dde", header: "Satış Parça" },
    { name: "KDV", color: "#b7190f", header: "Satış KDV" },
    { name: "totalSell", color: "#aca620", header: "Satış Toplam" },
    { name: "totalDiff", color: "#bbaabb", header: "Alım Satış Fark" },
  ]);

  const [renderedProdEntryLineKeys, setRenderedProdEntryLineKeys] = useState<{ name: string; color: string; header: string }[]>([
    { name: "boughtPriceAmount", color: "#6a0dad", header: "Parça Bedeli" },
    { name: "customs", color: "#cc0000", header: "Gümrük ve Diğer Masraf" },
    { name: "KKTC_KDV", color: "#487c90", header: "KDV Tutarı" },
    { name: "totalProdEntry", color: "#89a223", header: "Genel Toplam" },
  ]);
  const [exportButtonClicked, setExportButtonClicked] = useState<{ clicked: boolean; children?: React.ReactNode }>({ clicked: false });

  const selections: string[] = useSelector((state: GlobalState) => state.selections);
  const dispatch = useDispatch();

  useEffect(() => {
    setRenderedWorkOrderLineKeys(renderedWorkOrderLineKeys.filter((lineKey) => selections.some((selection) => selection === lineKey.name)));
    setRenderedProdEntryLineKeys(renderedProdEntryLineKeys.filter((lineKey) => selections.some((selection) => selection === lineKey.name)));
  }, []);

  const changeWorkOrderLinekey = (param: { name: string; color: string; header: string }) => {
    if (renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === param.name)) {
      setRenderedWorkOrderLineKeys(renderedWorkOrderLineKeys.filter((linekey) => linekey.name !== param.name));
      dispatch(
        CHANGE_SELECTION([
          ...renderedWorkOrderLineKeys.filter((linekey) => linekey.name !== param.name).map((lineKey) => lineKey.name),
          ...renderedProdEntryLineKeys.map((lineKey) => lineKey.name),
        ])
      );
    } else {
      setRenderedWorkOrderLineKeys([...renderedWorkOrderLineKeys, param]);
      dispatch(CHANGE_SELECTION([...[...renderedWorkOrderLineKeys, param].map((lineKey) => lineKey.name), ...renderedProdEntryLineKeys.map((lineKey) => lineKey.name)]));
    }
  };
  const changeProdEntryLineKey = (param: { name: string; color: string; header: string }) => {
    if (renderedProdEntryLineKeys.some((lineKey) => lineKey.name === param.name)) {
      setRenderedProdEntryLineKeys(renderedProdEntryLineKeys.filter((linekey) => linekey.name !== param.name));
      dispatch(
        CHANGE_SELECTION([
          ...renderedProdEntryLineKeys.filter((linekey) => linekey.name !== param.name).map((lineKey) => lineKey.name),
          ...renderedWorkOrderLineKeys.map((lineKey) => lineKey.name),
        ])
      );
    } else {
      setRenderedProdEntryLineKeys([...renderedProdEntryLineKeys, param]);
      dispatch(CHANGE_SELECTION([...[...renderedProdEntryLineKeys, param].map((lineKey) => lineKey.name), ...renderedWorkOrderLineKeys.map((lineKey) => lineKey.name)]));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .getStatistics()
      .then((result) => setStatisticsData(result))
      .finally(() => setIsLoading(false));
  }, []);

  const CustomWorkOrderTooltip: React.FC<any> = ({ active, payload }) => {
    if (active && payload.length > 0) {
      return (
        <div className="customTooltipWrapper">
          {renderedWorkOrderLineKeys.map((lineKey) => {
            return (
              <div>
                {lineKey.header} : {numberWithCommas(Math.round(payload[0].payload[lineKey.name] * 100) / 100)}{" "}
              </div>
            );
          })}
          {/* <div>Parça Bedeli {numberWithCommas(Math.round(payload[0].payload.boughtPriceAmountTimesAmount * 100) / 100)} </div>
          <div>Gümrük ve Diğer Masraf : {numberWithCommas(Math.round(payload[0].payload.customsTimesAmount * 100) / 100)}</div>
          <div>KDV Tutarı :{numberWithCommas(Math.round(payload[0].payload.KKTC_KDVTimesAmount * 100) / 100)} </div>
          <div>Toplam Alım :{numberWithCommas(Math.round(payload[0].payload.total * 100) / 100)}</div>
          <div>Satış İşçilik :{numberWithCommas(Math.round(payload[0].payload.workDone * 100) / 100)} </div>
          <div>Satış Parça :{numberWithCommas(Math.round(payload[0].payload.productsSold * 100) / 100)} </div>
          <div>Satış KDV :{numberWithCommas(Math.round(payload[0].payload.KDV * 100) / 100)} </div>
          <div>Satış Toplam :{numberWithCommas(Math.round(payload[0].payload.totalSell * 100) / 100)} </div>
          <div>Alım Satış Fark :{numberWithCommas(Math.round(payload[0].payload.totalDiff * 100) / 100)} </div> */}
        </div>
      );
    }

    return null;
  };

  const CustomProdEntryTooltip: React.FC<any> = ({ active, payload }) => {
    if (active && payload.length > 0) {
      return (
        <div className="customTooltipWrapper">
          {renderedProdEntryLineKeys.map((lineKey) => {
            return (
              <div>
                {" "}
                {lineKey.header} : {numberWithCommas(Math.round(payload[0].payload[lineKey.name] * 100) / 100)}
              </div>
            );
          })}
          {/*  <div>Parça Bedeli: {numberWithCommas(Math.round(payload[0].payload.boughtPriceAmount * 100) / 100)} </div>
          <div>Gümrük ve Diğer Masraf : {numberWithCommas(Math.round(payload[0].payload.customs * 100) / 100)}</div>
          <div>KDV Tutarı :{numberWithCommas(Math.round(payload[0].payload.KKTC_KDV * 100) / 100)} </div>
          <div>Genel Toplam :{numberWithCommas(Math.round(payload[0].payload.totalProdEntry * 100) / 100)} </div> */}
        </div>
      );
    }

    return null;
  };

  const CustomCustomerTooltip: React.FC<any> = ({ active, payload }) => {
    if (active && payload.length > 0) {
      if (payload[0].name === 0) {
        return (
          <div className="customTooltipWrapper">
            <div style={{ color: payload[0].payload.color }}>Toplam Alınan: {payload[0].payload.label} </div>
          </div>
        );
      }
      if (payload[0].name === 1) {
        return (
          <div className="customTooltipWrapper">
            <div style={{ color: payload[0].payload.color }}>Toplam Fatura: {payload[0].payload.label} </div>
          </div>
        );
      }
      if (payload[0].name === 2) {
        return (
          <div className="customTooltipWrapper">
            <div style={{ color: payload[0].payload.color }}>Toplam Alınacak: {payload[0].payload.label} </div>
          </div>
        );
      }
    }

    return null;
  };

  const CustomStockCostAmountToolTip: React.FC<any> = ({ active, payload }) => {
    if (active && payload.length > 0) {
      return (
        <div className="customTooltipWrapper">
          <div>Önceki Yıl Devir: {numberWithCommas(Math.round(payload[0].payload.lastYearAmount * 100) / 100)} </div>
          <div>Alım Maliyet : {numberWithCommas(Math.round(payload[0].payload.boughtAmount * 100) / 100)}</div>
          <div>İş Emri Satış :{numberWithCommas(Math.round(payload[0].payload.workOrderSold * 100) / 100)} </div>
          <div>Kalan Stok Değeri :{numberWithCommas(Math.round(payload[0].payload.stockLeft * 100) / 100)} </div>
        </div>
      );
    }

    return null;
  };

  const workOrderGraph = (
    <div className="dashBoardGraphWrapper">
      <h2 style={{ textAlign: "center", color: "var(--primary-color)" }}>İş Emri Satış Alım</h2>

      <LineChart
        width={750}
        height={300}
        data={statisticsData?.WorkOrderStatistics.map((data, index) => {
          return {
            boughtPriceAmountTimesAmount: data.boughtPriceAmountTimesAmount === 0 ? null : data.boughtPriceAmountTimesAmount,
            customsTimesAmount: data.customsTimesAmount === 0 ? null : data.customsTimesAmount,
            KKTC_KDVTimesAmount: data.KKTC_KDVTimesAmount === 0 ? null : data.KKTC_KDVTimesAmount,
            total: data.total === 0 ? null : data.total,
            workDone: statisticsData.SellingStatistics[index].workDone === 0 ? null : statisticsData.SellingStatistics[index].workDone,
            productsSold: statisticsData.SellingStatistics[index].productsSold === 0 ? null : statisticsData.SellingStatistics[index].productsSold,
            KDV: statisticsData.SellingStatistics[index].KDV === 0 ? null : statisticsData.SellingStatistics[index].KDV,
            totalSell: statisticsData.SellingStatistics[index].totalSell === 0 ? null : statisticsData.SellingStatistics[index].totalSell,
            totalDiff: statisticsData.SellingStatistics[index].totalSell - data.total === 0 ? null : statisticsData.SellingStatistics[index].totalSell - data.total,
            month: getMonthName(index),
          };
        })}
      >
        <XAxis dataKey="month" />
        <Tooltip content={<CustomWorkOrderTooltip />} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        {renderedWorkOrderLineKeys.map((lineKey) => {
          return <Line animationDuration={2500} type="monotone" strokeWidth={2} dataKey={lineKey.name} stroke={lineKey.color} />;
        })}
      </LineChart>
      <div>
        <span className="dashboardCheckboxWrapper">
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="boughtPriceAmountTimesAmount"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "boughtPriceAmountTimesAmount")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "boughtPriceAmountTimesAmount", color: "#6a0dad", header: "Alım Parça Bedeli" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "boughtPriceAmountTimesAmount") ? "#f5f5f5" : "#6a0dad",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "boughtPriceAmountTimesAmount") ? "#6a0dad" : "#f5f5f5",
                border: "1px solid #6a0dad",
                textAlign: "center",
              }}
              htmlFor="boughtPriceAmountTimesAmount"
            >
              Alım Parça Bedeli
            </label>
          </span>
          <span className="dashboardCheckbox">
            <input
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "customsTimesAmount")}
              type="checkbox"
              id="customsTimesAmount"
              onClick={() => {
                changeWorkOrderLinekey({ name: "customsTimesAmount", color: "#cc0000", header: "Alım Gümrük ve Diğer Masraf" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "customsTimesAmount") ? "#f5f5f5" : "#cc0000",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "customsTimesAmount") ? "#cc0000" : "#f5f5f5",
                border: "1px solid #cc0000",
              }}
              htmlFor="customsTimesAmount"
            >
              Alım Gümrük & Diğer Masraflar
            </label>
          </span>
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="KKTC_KDVTimesAmount"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "KKTC_KDVTimesAmount")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "KKTC_KDVTimesAmount", color: "#487c90", header: "Alım KDV Tutarı" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "KKTC_KDVTimesAmount") ? "#f5f5f5" : "#487c90",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "KKTC_KDVTimesAmount") ? "#487c90" : "#f5f5f5",
                border: "1px solid #487c90",
              }}
              htmlFor="KKTC_KDVTimesAmount"
            >
              Alım KDV Tutarı
            </label>
          </span>
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="total"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "total")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "total", color: "#89a223", header: "Alım Toplam" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "total") ? "#f5f5f5" : "#89a223",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "total") ? "#89a223" : "#f5f5f5",
                border: "1px solid #89a223",
              }}
              htmlFor="total"
            >
              Alım Toplam
            </label>
          </span>
        </span>

        <span className="dashboardCheckboxWrapper">
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="workDone"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "workDone")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "workDone", color: "#77533a", header: "Satış İşçilik" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "workDone") ? "#f5f5f5" : "#77533a",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "workDone") ? "#77533a" : "#f5f5f5",
                border: "1px solid #77533a",
              }}
              htmlFor="workDone"
            >
              İş Emri Satış İşçilik
            </label>
          </span>
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="productsSold"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "productsSold")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "productsSold", color: "#628dde", header: "Parça Satış" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "productsSold") ? "#f5f5f5" : "#628dde",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "productsSold") ? "#628dde" : "#f5f5f5",
                border: "1px solid #628dde",
              }}
              htmlFor="productsSold"
            >
              İş Emri Satış Parça
            </label>
          </span>
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="KDV"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "KDV")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "KDV", color: "#b7190f", header: "Satış KDV Tutarı" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "KDV") ? "#f5f5f5" : "#b7190f",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "KDV") ? "#b7190f" : "#f5f5f5",
                border: "1px solid #b7190f",
              }}
              htmlFor="KDV"
            >
              Satış KDV Tutarı
            </label>
          </span>
          <span className="dashboardCheckbox">
            <input
              id="totalSell"
              type="checkbox"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "totalSell")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "totalSell", color: "#aca620", header: "Satış Toplam" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "totalSell") ? "#f5f5f5" : "#aca620",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "totalSell") ? "#aca620" : "#f5f5f5",
                border: "1px solid #aca620",
              }}
              htmlFor="totalSell"
            >
              Satış Toplam
            </label>
          </span>
        </span>
        <span className="dashboardCheckboxWrapper">
          <span className="dashboardCheckbox">
            <input
              type="checkbox"
              id="totalDiff"
              checked={renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "totalDiff")}
              onClick={() => {
                changeWorkOrderLinekey({ name: "totalDiff", color: "#bbaabb", header: "Alım Satış Fark" });
              }}
            />
            <label
              style={{
                color: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "totalDiff") ? "#f5f5f5" : "#bbaabb",
                backgroundColor: renderedWorkOrderLineKeys.some((lineKey) => lineKey.name === "totalDiff") ? "#bbaabb" : "#f5f5f5",
                border: "1px solid #bbaabb",
              }}
              htmlFor="totalDiff"
            >
              Alım Satış Fark
            </label>
          </span>
        </span>
      </div>
    </div>
  );

  const prodEntryGraph = (
    <div className="dashBoardGraphWrapper">
      <h2 style={{ textAlign: "center", color: "var(--primary-color)" }}>Stok + Hizmet Alım</h2>

      <BarChart
        width={750}
        height={300}
        data={statisticsData?.productEntryStatistics.map((prodEntryStat, i) => {
          return {
            boughtPriceAmount: prodEntryStat.boughtPriceAmount === 0 ? null : prodEntryStat.boughtPriceAmount,
            customs: prodEntryStat.customs === 0 ? null : prodEntryStat.customs,
            KKTC_KDV: prodEntryStat.KKTC_KDV === 0 ? null : prodEntryStat.KKTC_KDV,
            totalProdEntry: prodEntryStat.totalProdEntry === 0 ? null : prodEntryStat.totalProdEntry,
            month: getMonthName(i),
          };
        })}
      >
        <XAxis dataKey="month" />
        <Tooltip content={<CustomProdEntryTooltip />} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        {renderedProdEntryLineKeys.map((lineKey) => {
          return <Bar animationDuration={2500} type="monotone" dataKey={lineKey.name} fill={lineKey.color} />;
        })}
      </BarChart>
      <span className="dashboardCheckboxWrapper">
        <span className="dashboardCheckbox">
          <input
            type="checkbox"
            id="boughtPriceAmount"
            checked={renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "boughtPriceAmount")}
            onClick={() => {
              changeProdEntryLineKey({ name: "boughtPriceAmount", color: "#6a0dad", header: "Parça Bedeli" });
            }}
          />
          <label
            style={{
              color: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "boughtPriceAmount") ? "#f5f5f5" : "#6a0dad",
              backgroundColor: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "boughtPriceAmount") ? "#6a0dad" : "#f5f5f5",
              border: "1px solid #6a0dad",
            }}
            htmlFor="boughtPriceAmount"
          >
            Parça Bedeli
          </label>
        </span>
        <span className="dashboardCheckbox">
          <input
            checked={renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "customs")}
            type="checkbox"
            id="customs"
            onClick={() => {
              changeProdEntryLineKey({ name: "customs", color: "#cc0000", header: "Gümrük ve Diğer Masraflar" });
            }}
          />
          <label
            style={{
              color: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "customs") ? "#f5f5f5" : "#cc0000",
              backgroundColor: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "customs") ? "#cc0000" : "#f5f5f5",
              border: "1px solid #cc0000",
            }}
            htmlFor="customs"
          >
            Gümrük & Diğer Masraflar
          </label>
        </span>
        <span className="dashboardCheckbox">
          <input
            type="checkbox"
            id="KKTC_KDV"
            checked={renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "KKTC_KDV")}
            onClick={() => {
              changeProdEntryLineKey({ name: "KKTC_KDV", color: "#487c90", header: "KDV Tutarı" });
            }}
          />
          <label
            style={{
              color: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "KKTC_KDV") ? "#f5f5f5" : "#487c90",
              backgroundColor: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "KKTC_KDV") ? "#487c90" : "#f5f5f5",
              border: "1px solid #487c90",
            }}
            htmlFor="KKTC_KDV"
          >
            KDV Tutarı
          </label>
        </span>
        <span className="dashboardCheckbox">
          <input
            type="checkbox"
            id="totalProdEntry"
            checked={renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "totalProdEntry")}
            onClick={() => {
              changeProdEntryLineKey({ name: "totalProdEntry", color: "#89a223", header: "Genel Toplam" });
            }}
          />
          <label
            style={{
              color: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "totalProdEntry") ? "#f5f5f5" : "#89a223",
              backgroundColor: renderedProdEntryLineKeys.some((lineKey) => lineKey.name === "totalProdEntry") ? "#89a223" : "#f5f5f5",
              border: "1px solid #89a223",
            }}
            htmlFor="totalProdEntry"
          >
            Genel Toplam
          </label>
        </span>
      </span>
    </div>
  );

  const stockCostAmount = (
    <div className="dashBoardGraphWrapper" style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center", color: "var(--primary-color)" }}>Stok Maliyet Tutarı</h2>

      <BarChart
        width={750}
        height={300}
        data={statisticsData?.productEntryStatistics.map((monthlyProdEntryStat, i) => {
          return {
            lastYearAmount: 0,
            boughtAmount: monthlyProdEntryStat.totalProdEntry,
            workOrderSold: statisticsData.WorkOrderStatistics[i].total,
            stockLeft:
              monthlyProdEntryStat.totalProdEntry === 0 && statisticsData.WorkOrderStatistics[i].total === 0
                ? null
                : statisticsData?.productEntryStatistics.slice(0, i + 1).reduce((a, b) => a + b.totalProdEntry, 0) -
                  statisticsData.WorkOrderStatistics.slice(0, i + 1).reduce((a, b) => a + b.total, 0),
            month: getMonthName(i),
          };
        })}
      >
        <XAxis dataKey="month" />
        <Tooltip content={<CustomStockCostAmountToolTip />} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar animationDuration={2500} type="monotone" dataKey="stockLeft" fill="#0f4539" />
      </BarChart>
    </div>
  );

  const workOrdersTable = (
    <div>
      <div>
        <div className="dashboardTableHeaderWrapper">
          <div className="dashboardTableHeader">İş Emri Satış Alım</div>
        </div>

        <table className="dashboardTable">
          <thead>
            <tr>
              <th style={{ width: "10%" }}></th>
              <th>Oca</th>
              <th>Şub</th>
              <th>Mar</th>
              <th>Nis</th>
              <th>May</th>
              <th>Haz</th>
              <th>Tem</th>
              <th>Ağu</th>
              <th>Eyl</th>
              <th>Eki</th>
              <th>Kas</th>
              <th>Ara</th>
              <th>Toplam</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="dashboardHeaderTd">Parça Bedeli</td>

              {statisticsData?.WorkOrderStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.boughtPriceAmountTimesAmount * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.WorkOrderStatistics.reduce((a, b) => a + b.boughtPriceAmountTimesAmount, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">Gümrük & Diğer Masraflar</td>

              {statisticsData?.WorkOrderStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.customsTimesAmount * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.WorkOrderStatistics.reduce((a, b) => a + b.customsTimesAmount, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">KDV Tutarı</td>

              {statisticsData?.WorkOrderStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.KKTC_KDVTimesAmount * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.WorkOrderStatistics.reduce((a, b) => a + b.KKTC_KDVTimesAmount, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">Toplam Alım</td>

              {statisticsData?.WorkOrderStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.total * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.WorkOrderStatistics.reduce((a, b) => a + b.total, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">İş Emri Satış İşçilik</td>

              {statisticsData?.SellingStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.workDone * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.SellingStatistics.reduce((a, b) => a + b.workDone, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">İş Emri Satış Parça</td>

              {statisticsData?.SellingStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.productsSold * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.SellingStatistics.reduce((a, b) => a + b.productsSold, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">İş Emri Satış KDV</td>

              {statisticsData?.SellingStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.KDV * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.SellingStatistics.reduce((a, b) => a + b.KDV, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">İş Emri Satış </td>

              {statisticsData?.SellingStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.totalSell * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.SellingStatistics.reduce((a, b) => a + b.totalSell, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">Alım Satış Fark </td>

              {statisticsData?.WorkOrderStatistics.map((monthlyStat, index) => (
                <td>{numberWithCommas(Math.round((statisticsData.SellingStatistics[index].totalSell - monthlyStat.total) * 100) / 100)}</td>
              ))}
              <td>
                {statisticsData
                  ? numberWithCommas(
                      Math.round(
                        (statisticsData?.SellingStatistics.reduce((a, b) => a + b.totalSell, 0) - statisticsData.WorkOrderStatistics.reduce((a, b) => a + b.total, 0)) * 100
                      ) / 100
                    )
                  : 0}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  const prodEntryTable = (
    <div>
      <div>
        <div className="dashboardTableHeaderWrapper">
          <div className="dashboardTableHeader">Stok + Hizmet Alım</div>
        </div>

        <table className="dashboardTable">
          <thead>
            <tr>
              <th style={{ width: "10%" }}></th>
              <th>Oca</th>
              <th>Şub</th>
              <th>Mar</th>
              <th>Nis</th>
              <th>May</th>
              <th>Haz</th>
              <th>Tem</th>
              <th>Ağu</th>
              <th>Eyl</th>
              <th>Eki</th>
              <th>Kas</th>
              <th>Ara</th>
              <th>Toplam</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="dashboardHeaderTd">Parça Bedeli</td>

              {statisticsData?.productEntryStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.boughtPriceAmount * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.productEntryStatistics.reduce((a, b) => a + b.boughtPriceAmount, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">Gümrük & Diğer Masraflar</td>

              {statisticsData?.productEntryStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.customs * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.productEntryStatistics.reduce((a, b) => a + b.customs, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">KDV Tutarı</td>

              {statisticsData?.productEntryStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.KKTC_KDV * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.productEntryStatistics.reduce((a, b) => a + b.KKTC_KDV, 0) * 100) / 100) : 0}</td>
            </tr>
            <tr>
              <td className="dashboardHeaderTd">Toplam Alım</td>

              {statisticsData?.productEntryStatistics.map((monthlyStat) => (
                <td>{numberWithCommas(Math.round(monthlyStat.totalProdEntry * 100) / 100)}</td>
              ))}
              <td>{statisticsData ? numberWithCommas(Math.round(statisticsData?.productEntryStatistics.reduce((a, b) => a + b.totalProdEntry, 0) * 100) / 100) : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  const pieChartData = useMemo(() => {
    return [
      {
        color: "#5cad4b",
        label: numberWithCommas(Math.round((statisticsData?.customerInformation.totalAmountReimbursed ?? 0) * 100) / 100),
        value: statisticsData?.customerInformation.totalAmountReimbursed,
      },
      /* {
        color: "#324960",
        label: numberWithCommas(Math.round((statisticsData?.customerInformation.totalAmountSpent ?? 0) * 100) / 100),
        value: statisticsData?.customerInformation.totalAmountSpent,
      }, */
      {
        color: "#f57c00",
        label: numberWithCommas(Math.round((statisticsData?.customerInformation.totalRemainingPayment ?? 0) * 100) / 100),
        value: statisticsData?.customerInformation.totalRemainingPayment,
      },
    ];
  }, [statisticsData?.customerInformation.totalAmountReimbursed, statisticsData?.customerInformation.totalRemainingPayment]);

  if (isLoading) {
    return (
      <div className="LoaderWrapper">
        <ThreeCircles height="80" width="80" color="var(--primary-color)" />
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="dashboardWrapper">
        {exportButtonClicked.clicked ? (
          <ExportBillPopUp
            close={() => {
              setExportButtonClicked({ clicked: false });
            }}
            scale={0.55}
            sideways={true}
            fileName={`Dashboard ${moment(currentDate).format("YYYY")}`}
          >
            {exportButtonClicked.children}
          </ExportBillPopUp>
        ) : null}
        <div className="dashboardHeaderWrapper">
          <h1 className="dashboardHeader">{DASHBOARD_ROUTE.TITLE}</h1>
        </div>
        <div>
          <select className="dashboardYearSelect">
            <option>2024</option>
          </select>
        </div>
        <div className="topChartsWrapper">
          <div className="dashboardGraphs">
            <button
              className="exportBtn"
              onClick={() => {
                setExportButtonClicked({
                  clicked: true,
                  children: (
                    <div>
                      {workOrdersTable}
                      {prodEntryTable}
                    </div>
                  ),
                });
              }}
            >
              <b>PDF olarak indir</b>
            </button>
            {workOrderGraph}
            {/*  <button
              className="exportBtn"
              onClick={() =>
                setExportButtonClicked({
                  clicked: true,
                  children: (
                    <div className="exportDashboard">
                      {workOrderGraph}
                      {workOrdersTable}
                    </div>
                  ),
                })
              }
            >
              PDF olarak çıkart
            </button> */}
            {prodEntryGraph}
            {stockCostAmount}
          </div>
          <div className="dashboardTableWrapper">
            <span className="dashboardCustomerInfoWrapper">
              {/* <span>
                <div className="customerInfoCardWrapper">
                  <div className="customerInfoHeader">Toplam Fatura</div>
                  <div className="customerInfoValue">{statisticsData ? numberWithCommas(Math.round(statisticsData?.customerInformation.totalAmountSpent * 100) / 100) : null}</div>
                </div>
              </span>
              <span>
                <div className="customerInfoCardWrapper">
                  <div className="customerInfoHeader">Toplam Alınan</div>
                  <div className="customerInfoValue">
                    {statisticsData ? numberWithCommas(Math.round(statisticsData?.customerInformation.totalAmountReimbursed * 100) / 100) : null}
                  </div>
                </div>
              </span>
              <span>
                <div className="customerInfoCardWrapper">
                  <div className="customerInfoHeader">Toplam Alacak</div>
                  <div className="customerInfoValue">
                    {statisticsData ? numberWithCommas(Math.round(statisticsData?.customerInformation.totalRemainingPayment * 100) / 100) : null}
                  </div>
                </div>
              </span> */}
              <div>
                <h2 style={{ textAlign: "center", color: "var(--primary-color)", marginTop: "0" }}>Müşteri Bilgileri</h2>
                <div className="dashBoardPieWrapper">
                  <PieChart data={pieChartData} width={300} height={300}>
                    <Pie
                      data={pieChartData}
                      dataKey="value"
                      fill="#8884d8"
                      startAngle={0}
                      endAngle={180}
                      label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
                            {`${(percent * 100).toFixed(0)}%`}
                          </text>
                        );
                      }}
                      labelLine={false}
                    >
                      {pieChartData.map((chartData) => (
                        <Cell fill={chartData.color} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomCustomerTooltip />} />
                  </PieChart>

                  <div className="customerInfoCardWrapper">
                    <div className="customerInfoHeader">Toplam Fatura</div>
                    <div className="customerInfoValue">
                      {statisticsData ? numberWithCommas(Math.round(statisticsData?.customerInformation.totalAmountSpent * 100) / 100) : null}
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <div>
              <div className="table-title">
                <h3 style={{ color: "var(--primary-color)", textAlign: "center" }}>Müşteriler</h3>
              </div>
              <table className="table-fill">
                <thead>
                  <tr>
                    <th className="text-left">Müşteri</th>
                    <th className="text-left">Alınacak</th>
                  </tr>
                </thead>
                <tbody className="table-hover">
                  {statisticsData?.customerInformation.customersWithDebt.map((customerWithDebt) => {
                    return (
                      <tr>
                        <td className="text-left">{customerWithDebt.name}</td>
                        <td className="text-left">{numberWithCommas(Math.round(customerWithDebt.debt * 100) / 100)}€</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
