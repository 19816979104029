import { Reducer } from "redux";
import { Currency } from "../../Models/Currency";

interface IAction {
  type: string;
  payload: Currency;
}

const CurrencyReducer: Reducer<Currency, IAction> = (state: Currency = { rate: 0, ratePound: 0, rateUSD: 0 }, action: IAction): Currency => {
  switch (action.type) {
    case "GOT_CURRENCY_FINALLY":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default CurrencyReducer;
