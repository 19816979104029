import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, HOME_ROUTE, LOGIN_ROUTE, MACHINES_ALL_ROUTE, STOCK_ALL_ROUTE, STOCK_ROUTE, WORK_ORDER_ALL_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import "./Navbar.scss";
import image from "./gurdag.png";
import { useDispatch } from "react-redux";
import { LOGOUT_FINALLY } from "../../Redux/UserActions";

export const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const handleLogOut = () => {
    dispatch(LOGOUT_FINALLY());
    localStorage.clear();
    navigate(`${LOGIN_ROUTE.PATH}`);
  };

  return (
    <div className="NavbarWrapper">
      <img src={image} alt="img" />
      <div className="navbarHeader">Collective-R</div>
      <div className="navbarItemsWrapper">
        <div className="navbarItem" onClick={() => navigate(HOME_ROUTE.PATH)}>
          {HOME_ROUTE.TITLE}
        </div>
        <div className="navbarItem" onClick={() => navigate(WORK_ORDER_ALL_ROUTE.PATH)}>
          {WORK_ORDER_ALL_ROUTE.TITLE}
        </div>
        <div className="navbarItem" onClick={() => navigate(STOCK_ROUTE.PATH)}>
          {STOCK_ROUTE.TITLE}
        </div>
        <div className="navbarItem" onClick={() => navigate(STOCK_ALL_ROUTE.PATH)}>
          {STOCK_ALL_ROUTE.TITLE}
        </div>
        <div className="navbarItem" onClick={() => navigate(MACHINES_ALL_ROUTE.PATH)}>
          {MACHINES_ALL_ROUTE.TITLE}
        </div>
        <div className="navbarItem" onClick={() => navigate(DASHBOARD_ROUTE.PATH)}>
          {DASHBOARD_ROUTE.TITLE}
        </div>
      </div>
      {user[0].name ? <div className="navbarUserName">{user[0].name.toUpperCase()}</div> : null}
      <div className="logOut" onClick={handleLogOut}>
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 14.25C8.58579 14.25 8.25 14.5858 8.25 15C8.25 15.4142 8.58579 15.75 9 15.75H15C15.9665 15.75 16.75 14.9665 16.75 14V2C16.75 1.0335 15.9665 0.25 15 0.25H9C8.58579 0.25 8.25 0.585788 8.25 1C8.25 1.41421 8.58579 1.75 9 1.75L15 1.75C15.1381 1.75 15.25 1.86193 15.25 2L15.25 14C15.25 14.1381 15.1381 14.25 15 14.25H9Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5031 10.3652C12.1934 10.3652 12.7531 9.80557 12.7531 9.11522V6.8747C12.7531 6.18435 12.1934 5.6247 11.5031 5.6247L6.89048 5.6247C6.88396 5.55128 6.87713 5.47787 6.87 5.40448L6.81597 4.8486C6.73354 4.00049 5.83294 3.49258 5.06451 3.86084C3.43029 4.64403 1.95085 5.71578 0.697355 7.02454L0.598156 7.12811C0.133948 7.61278 0.133948 8.37712 0.598155 8.8618L0.697355 8.96537C1.95085 10.2741 3.43029 11.3459 5.06451 12.1291C5.83293 12.4973 6.73354 11.9894 6.81597 11.1413L6.87 10.5854C6.87713 10.512 6.88396 10.4386 6.89048 10.3652H11.5031ZM6.19511 8.86522C5.92874 8.86522 5.69326 9.00451 5.56008 9.21598C5.49523 9.31896 5.45464 9.43906 5.44656 9.56853C5.42842 9.85941 5.40524 10.15 5.37703 10.4403L5.36135 10.6017C4.3253 10.0677 3.36316 9.40283 2.49838 8.62391C2.27402 8.42183 2.05622 8.21208 1.84538 7.99496C2.86892 6.9409 4.05651 6.06069 5.36135 5.38824L5.37703 5.54959C5.40524 5.83987 5.42842 6.13051 5.44656 6.42139C5.47122 6.81671 5.79902 7.1247 6.19511 7.1247H11.2531V8.86522H6.19511Z"
          />
        </svg>
        Çıkış Yap
      </div>
    </div>
  );
};
