import { useEffect, useState } from "react";
import { CustomerDetails } from "../../Models/CustomerDetails";
import "./BillPart.scss";
import { PaymentEntries } from "../../Models/PaymentEntries";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import tr from "date-fns/locale/tr";
import { ExportBillPopUp } from "../PopUps/ExportBillPopUp/ExportBillPopUp";
import { EditPaymentPopUp } from "../PopUps/EditPaymentPopUp/EditPaymentPopUp";
registerLocale("tr", tr);

interface IBillPart {
  customerDetails: CustomerDetails;
  updatedCustomer: (customerDetails: CustomerDetails) => void;
}

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const currentDate = new Date();

export const BillPart: React.FC<IBillPart> = ({ customerDetails, updatedCustomer }) => {
  const [paymentEntry, setPaymentEntry] = useState<PaymentEntries>({
    payedAmount: 0,
    paymentDescription: "",
    date: null,
  });
  const [paymentWorkOrderArr, setPaymentWorkOrderArr] = useState<any[]>([...customerDetails.workOrders.filter((wO) => wO.bill), ...customerDetails.paymentEntries]);
  const [exportPopUp, setExportPopUp] = useState<boolean>(false);
  const [editSelectedIndex, setEditSelectedIndex] = useState<{ clicked: boolean; index: number }>({ clicked: false, index: -1 });

  useEffect(() => {
    setPaymentWorkOrderArr([...customerDetails.workOrders.filter((wO) => wO.bill), ...customerDetails.paymentEntries]);
  }, [customerDetails]);

  /* useEffect(() => {
    setPaymentWorkOrderArr([...customerDetails.workOrders.filter((wO) => wO.bill), ...customerDetails.paymentEntries]);
  }, [customerDetails]); */

  //WORKORDER BOŞ GÖNDERİLİYOR PAYLOAD BOYUTUNU DÜŞÜREBİLMEK İÇİN

  const handleBillUpdate = (paymenEntry: PaymentEntries) => {
    updatedCustomer({
      ...customerDetails,
      paymentInfo: {
        ...customerDetails.paymentInfo,
        amountReimbursed: customerDetails.paymentInfo.amountReimbursed + paymenEntry.payedAmount,
      },
      paymentEntries: [...customerDetails.paymentEntries, paymenEntry],
      workOrders: [],
    });
  };

  //WORKORDER BOŞ GÖNDERİLİYOR PAYLOAD BOYUTUNU DÜŞÜREBİLMEK İÇİN

  const handleBillPaymentEdit = (paymenEntry: PaymentEntries) => {
    updatedCustomer({
      ...customerDetails,
      paymentInfo: {
        ...customerDetails.paymentInfo,
        amountReimbursed: customerDetails.paymentEntries
          .map((entry, index) => {
            if (index === editSelectedIndex.index) {
              return paymenEntry;
            }
            return entry;
          })
          .reduce((a, b) => a + b.payedAmount, 0),
      },
      paymentEntries: customerDetails.paymentEntries.map((entry, index) => {
        if (index === editSelectedIndex.index) {
          return paymenEntry;
        }
        return entry;
      }),
      workOrders: [],
    });
  };

  const exportComponent = (
    <div className="pdfWrapper">
      <>
        <div className="pdfInfoWrapper">
          <div>
            <div className="pdfBillcustomerDetailsHeaderWrapper">
              <div className="billcustomerDetailsHeader">{customerDetails.name}</div>
              <div className="customerDetailsId">{customerDetails.address}</div>
            </div>

            <span className="pdfBillInfoLeftItemsWrapper">CARİ HESAP EKSTRESİ</span>
          </div>
          <div className="pdfBillInfoRight">
            <div className="pdfDate">TARİH: {moment(currentDate).format("DD/MM/YYYY")}</div>
            <table>
              <thead>
                <th>ALACAK</th>
                <th>ALINAN</th>
                <th>KALAN</th>
              </thead>
              <tbody>
                <tr>
                  <td>{numberWithCommas(Math.round(customerDetails.paymentInfo.amountSpent * 100) / 100)}</td>
                  <td>{numberWithCommas(Math.round(customerDetails.paymentInfo.amountReimbursed * 100) / 100)}</td>
                  <td>{numberWithCommas(Math.round((customerDetails.paymentInfo.amountSpent - customerDetails.paymentInfo.amountReimbursed) * 100) / 100)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pdfBilltableWrapper">
          <table>
            <thead>
              <tr>
                <th className="pdfthDescription">Açıklama</th>
                <th className="pdftableBorder">Fatura Tarih</th>
                <th className="pdftableBorder">Fatura No</th>
                <th className="pdftableBorder">Alacak</th>
                <th className="pdftableBorder">Alınan</th>
              </tr>
            </thead>
            <tbody>
              {paymentWorkOrderArr
                .sort((a, b) => {
                  if (a.bill !== undefined && b.date !== undefined) {
                    return Date.parse(a.bill.date) - Date.parse(b.date);
                  } else if (a.bill !== undefined && b.bill !== undefined) {
                    return Date.parse(a.bill.date) - Date.parse(b.bill.date);
                  }
                  if (a.date !== undefined && b.bill !== undefined) {
                    return Date.parse(a.date) - Date.parse(b.bill.date);
                  } else {
                    return Date.parse(a.date) - Date.parse(b.date);
                  }
                })
                .map((paymentWorkOrder, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {paymentWorkOrder.status ? (
                          <>
                            {customerDetails.name} {paymentWorkOrder.hoursWorkdOnMachine} SAAT # {paymentWorkOrder._id}
                          </>
                        ) : (
                          <>{paymentWorkOrder.paymentDescription}</>
                        )}
                      </td>
                      <td>{paymentWorkOrder.bill ? moment(paymentWorkOrder.bill.date).format("DD/MM/YYYY") : moment(paymentWorkOrder.date).format("DD/MM/YYYY")}</td>
                      <td>{paymentWorkOrder.bill ? paymentWorkOrder._id : null}</td>
                      <td>{paymentWorkOrder.totalPrice ? numberWithCommas(paymentWorkOrder.totalPrice) : null}</td>
                      <td>{paymentWorkOrder.payedAmount ? numberWithCommas(paymentWorkOrder.payedAmount) : null}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );

  return (
    <div>
      <div className="billPartTopWrapper">
        {exportPopUp ? (
          <ExportBillPopUp fileName={`Fatura ${customerDetails.name} `} scale={0.8} close={() => setExportPopUp(false)}>
            {exportComponent}
          </ExportBillPopUp>
        ) : null}
        {editSelectedIndex.clicked ? (
          <EditPaymentPopUp
            close={() => setEditSelectedIndex({ clicked: false, index: -1 })}
            editedPaymentEntry={handleBillPaymentEdit}
            initPaymentEntry={customerDetails.paymentEntries[editSelectedIndex.index]}
          />
        ) : null}
        <div className="billPartCustomerInfoWrapper">
          <div className="billPartHeaderWrapper">
            <div>{customerDetails.name}</div>
            <div className="billPartHeaderId">MŞ. {customerDetails._customerId}</div>
          </div>
          <span className="billPartInfo">
            <span>
              <div className="billInfoHeader">Alacak</div>

              <div className="billInfoValue">{numberWithCommas(Math.round(customerDetails.paymentInfo.amountSpent * 100) / 100)}</div>
            </span>
            <span>
              <div className="billInfoHeader">Alınan</div>
              <div className="billInfoValue">{numberWithCommas(Math.round(customerDetails.paymentInfo.amountReimbursed * 100) / 100)}</div>
            </span>
            <span>
              <div className="billInfoHeader">Kalan</div>
              <div className="billInfoValue">
                {numberWithCommas(Math.round((customerDetails.paymentInfo.amountSpent - customerDetails.paymentInfo.amountReimbursed) * 100) / 100)}
              </div>
            </span>
          </span>
        </div>

        <div className="billPartInputBtnWrapper">
          <div className="billPartInputWrapper">
            <div>
              <div className="billPartInputHeader">Alınan Ücret:</div>
              <input
                type="number"
                step=".01"
                placeholder="Miktar Giriniz..."
                onChange={(event) => {
                  if (!isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) !== 0) {
                    setPaymentEntry({ ...paymentEntry, payedAmount: parseFloat(event.target.value) });
                  } else {
                    setPaymentEntry({ ...paymentEntry, payedAmount: 0 });
                  }
                }}
              />
            </div>
            <div>
              <div className="billPartInputHeader">Tarih:</div>
              <DatePicker
                value={paymentEntry.date !== null ? moment(paymentEntry.date).format("DD/MM/YYYY") : ""}
                placeholderText="Tarih Giriniz..."
                locale={tr}
                onChange={(date) => setPaymentEntry({ ...paymentEntry, date: date })}
              />
            </div>
            <div>
              <div className="billPartInputHeader">Ödeme Şekli:</div>
              <input
                placeholder="Açıklama Giriniz..."
                type="text"
                onChange={(event) => {
                  setPaymentEntry({ ...paymentEntry, paymentDescription: event.target.value });
                }}
              />
            </div>
          </div>
          <div>
            <button
              disabled={paymentEntry.payedAmount === 0 || paymentEntry.paymentDescription === "" || paymentEntry.date === null}
              onClick={() => {
                handleBillUpdate(paymentEntry);
              }}
              className="addBtn"
            >
              Ücreti Öde
            </button>
          </div>
        </div>
        <button onClick={() => setExportPopUp(true)} className="exportBtn">
          PDF Olarak Yazdır
        </button>
      </div>
      <span className="BillPaymentsWrapper">
        <span className="BillsWrapper">
          <table className="responstable">
            <thead>
              <tr>
                <th>İş Emri No</th>
                <th>Fatura No</th>
                <th>Makine</th>
                <th>Tarih</th>
                <th>Açıklama</th>
                <th>Alacak</th>
                <th>Alınan</th>
                <th>Ödeme Şekli</th>
                <th>Ödemeyi Düzenle</th>
              </tr>
            </thead>
            <tbody>
              {paymentWorkOrderArr
                .sort((a, b) => {
                  if (a.bill !== undefined && b.date !== undefined) {
                    return Date.parse(a.bill.date) - Date.parse(b.date);
                  } else if (a.bill !== undefined && b.bill !== undefined) {
                    return Date.parse(a.bill.date) - Date.parse(b.bill.date);
                  }
                  if (a.date !== undefined && b.bill !== undefined) {
                    return Date.parse(a.date) - Date.parse(b.bill.date);
                  } else {
                    return Date.parse(a.date) - Date.parse(b.date);
                  }
                })
                .map(
                  (item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item._id}</td>
                        <td>{item.bill ? item.bill.billNumber ?? "-" : ""}</td>
                        <td>{item.Machine ? item.Machine.modal : ""}</td>
                        <td>{item.bill ? moment(item.bill.date).format("DD/MM/YYYY") : moment(item.date).format("DD/MM/YYYY")}</td>
                        <td>{item.description ?? "ÖDEME"}</td>
                        <td>{item.totalPrice ? numberWithCommas(item.totalPrice) : ""}</td>
                        <td>{item.payedAmount ? `-${numberWithCommas(item.payedAmount)}` : ""}</td>
                        <td>{item.paymentDescription ?? ""}</td>
                        <td>
                          {item.paymentDescription ? (
                            <div
                              onClick={() => {
                                setEditSelectedIndex({
                                  clicked: true,
                                  //below we find the paymentEntry index for the customerDetails.paymentEntries part. Because the index on this map returns the index of the paymentWorkOrderaRR
                                  //  which is a different index
                                  index:
                                    paymentWorkOrderArr
                                      .sort((a, b) => {
                                        if (a.bill !== undefined && b.date !== undefined) {
                                          return Date.parse(a.bill.date) - Date.parse(b.date);
                                        } else if (a.bill !== undefined && b.bill !== undefined) {
                                          return Date.parse(a.bill.date) - Date.parse(b.bill.date);
                                        }
                                        if (a.date !== undefined && b.bill !== undefined) {
                                          return Date.parse(a.date) - Date.parse(b.bill.date);
                                        } else {
                                          return Date.parse(a.date) - Date.parse(b.date);
                                        }
                                      })
                                      .slice(0, index + 1)
                                      .filter((payment) => payment.paymentDescription).length - 1,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <svg width="20" height="22" viewBox="0 0 20 22" fill="#f37826" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.81 6.94L11.06 3.19L2 12.25V16H5.75L14.81 6.94ZM4 14V13.08L11.06 6.02L11.98 6.94L4.92 14H4Z" />
                                <path d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04Z" />
                                <path d="M20 18H0V22H20V18Z" />
                              </svg>
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    );
                  } /* (
                <tr>
                  <td>{item ?? ""}</td>
                  <td>{item.machine ?? ""}</td>
                  <td>{item.date}</td>
                  <td>{item.totalPrice ?? ""}</td>
                  <td>{item.payedAmount ?? ""}</td>
                  <td>{item.paymentDescription ?? ""}</td>
                </tr>
              ) */
                )}
            </tbody>
          </table>
        </span>
        {/* <span className="PaymentsWrapper">
          <h2>Ödeme Geçmişi</h2>
          {customerDetails.paymentEntries.map((entry) => (
            <div className="billCardWrapper">
              <div className="paymentCardsHeaderWrapper">
                <div>
                  <div className="paymentCardHeader">Ödeme Tarihi</div>
                  <div className="paymentCardValue"> {entry.date}</div>
                </div>
                <div>
                  <div className="paymentCardHeader">Alınan Miktar</div> <div className="paymentCardValue"> {entry.payedAmount}</div>
                </div>
              </div>
            </div>
          ))}
        </span> */}
      </span>
    </div>
  );
};
