import React, { useEffect, useState } from "react";
import { WorkOrder } from "../../../Models/WorkOrder";
import ReactSelect, { MultiValue, SingleValue } from "react-select";
import { Product } from "../../../Models/Product";
import { Machine } from "../../../Models/Machine";
import { ProductsToBeSold } from "../../../Models/ProductsToBeSold";
import classes from "./AddWorkOrderPopUp.module.scss";
import { WorkToBeSold } from "../../../Models/workToBeSold";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import { Percentage } from "../../../Models/Percentage";
import moment from "moment";

export type RSelectProductOption = {
  value: Product;
  label: string;
};

export type RSelectProductEntryOption = {
  value: ProductsToBeSold;
  label: string;
};
export type RSelectMachineOption = {
  value: Machine;
  label: string;
};

interface IAddWorkOrderPopUp {
  products: Product[];
  closed: (close: boolean) => void;
  createdWorkOrder?: (workOrder: WorkOrder) => void;
  updatedWorkOrder?: (WorkOrder: WorkOrder) => void;
  machines: Machine[];
  customerPercentages: Percentage;
  customerName: string;
  customerAddress: string;
  currency: number;
  workOrdertoOperate?: WorkOrder;
  defaultWorkPrice: number;
  searchProducts: (searchTerm: string) => void;
  productsLoading: boolean;
}

const initializedWorkOrder: WorkOrder = {
  description: "",
  _id: 0,
  _customerId: 0,
  Machine: { brand: "", modal: "", seriesNumber: "", productNumber: "", qCode: "", year: 0, _customerId: 0 },
  status: "OPEN",
  hoursWorkedOnMachine: 0,
  percentages: { piecePercentage: 18, workerPercentage: 18, KDVPercentage: 16 },
  paymentExpiration: 0,
  currency: "EUR",
  workToBeSold: [],
  productsToBeSold: [],
  totalPrice: 0,
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const currentDate = new Date();

const initState = (workOrdertoOperate: WorkOrder | undefined): WorkOrder => {
  if (workOrdertoOperate) {
    return workOrdertoOperate;
  } else {
    return initializedWorkOrder;
  }
};

export function ensure<T>(argument: T | undefined | null) {
  if (argument === undefined || argument === null) {
    return 0;
  }

  return argument;
}

export const AddWorkOrderPopUp: React.FC<IAddWorkOrderPopUp> = ({
  closed,
  createdWorkOrder,
  products,
  machines,
  customerPercentages,
  customerAddress,
  customerName,
  currency,
  updatedWorkOrder,
  workOrdertoOperate,
  defaultWorkPrice,
  searchProducts,
  productsLoading,
}) => {
  const [initWorkOrder, setInitWorkOrder] = useState<WorkOrder>({ ...initState(workOrdertoOperate), percentages: customerPercentages });

  const initWorkEntry: WorkToBeSold = {
    description: "",
    workPrice: defaultWorkPrice,
    workingHours: 0,
    workCode: "",
  };
  const [workEntry, setWorkEntry] = useState<WorkToBeSold>(initWorkEntry);
  const [currencyValue, setCurrencyValue] = useState<number>(currency);
  const [sumValuesofOrder, setSumValuesOfOrder] = useState<number>(0);
  const [sumValuesofWork, setSumValuesOfWork] = useState<number>(0);
  const [errorShown, setErrorShown] = useState<{ opened: boolean; text: string }>({ opened: false, text: "" });
  const [selectedProduct, setSelectedProduct] = useState<RSelectProductOption>();

  const handleSelectedProductChange = (newSelectedOption: MultiValue<RSelectProductOption> | SingleValue<RSelectProductOption>) => {
    const selectedProduct2 = newSelectedOption as RSelectProductOption;
    setSelectedProduct(selectedProduct2);
  };

  const handleSelectedMachineChange = (newSelectedOption: MultiValue<RSelectMachineOption> | SingleValue<RSelectMachineOption>) => {
    const selectedMachine = newSelectedOption as RSelectMachineOption;
    setInitWorkOrder({ ...initWorkOrder, Machine: selectedMachine.value });
  };

  const handleSelectedProductEntryChange = (newSelectedOption: MultiValue<RSelectProductEntryOption> | SingleValue<RSelectProductEntryOption>) => {
    const selectedProduct2 = newSelectedOption as RSelectProductEntryOption;
    setInitWorkOrder({ ...initWorkOrder, productsToBeSold: [...initWorkOrder.productsToBeSold, selectedProduct2.value] });
  };

  //11110668:VOE

  useEffect(() => {
    if (initWorkOrder.currency === "TL") {
      setCurrencyValue(currency);
    } else {
      setCurrencyValue(1);
    }
  }, [currency, initWorkOrder.currency]);

  useEffect(() => {
    setSumValuesOfOrder(
      initWorkOrder.productsToBeSold
        .map((item) => {
          return item.product.pricePerAmount * item.toBeSoldAmount;
        })
        .reduce((a, b) => a + b, 0)
    );

    setSumValuesOfWork(
      initWorkOrder.workToBeSold
        .map((item) => {
          return item.workPrice * item.workingHours;
        })
        .reduce((a, b) => a + b, 0)
    );
  }, [initWorkOrder.productsToBeSold, initWorkOrder.workToBeSold]);

  const exportComponent = (
    <div className={classes.pdfWrapper}>
      {initWorkOrder !== undefined ? (
        <>
          <div className={classes.pdfInfoWrapper}>
            <div>
              <div className={classes.pdfcustomerDetailsHeaderWrapper}>
                <div className={classes.customerDetailsHeader}>{customerName}</div>
                <div className={classes.customerDetailsId}>{customerAddress}</div>
              </div>

              <span className={classes.pdfInfoLeftItemsWrapper}>
                <div className={classes.pdfInfoLeftItem}>
                  <span className={classes.pdfInfoLeftHeader}>İŞ EMRİ TARİH </span>
                  <span className={classes.pdfInfoLeftValue}>{moment(currentDate).format("DD/MM/YYYY")}</span>
                </div>
                <div className={classes.pdfInfoLeftItem}>
                  <span className={classes.pdfInfoLeftHeader}>İŞ EMRİ NO </span>
                  <span className={classes.pdfInfoLeftValue}>{initWorkOrder._id}</span>
                </div>
                <div className={classes.pdfInfoLeftItem}>
                  <span className={classes.pdfInfoLeftHeader}>MAKİNE </span>
                  <span className={classes.pdfInfoLeftValue}>
                    {initWorkOrder.Machine.brand} - {initWorkOrder.Machine.modal}
                  </span>
                </div>
                <div className={classes.pdfInfoLeftItem}>
                  <span className={classes.pdfInfoLeftHeader}>SAAT </span>
                  <span className={classes.pdfInfoLeftValue}>{initWorkOrder.hoursWorkedOnMachine}</span>
                </div>
                <div className={classes.pdfStatusWrapper}>{initWorkOrder.status === "OFFER" ? "TEKLİF" : initWorkOrder.status === "OPEN" ? "AÇIK" : "KAPALI"}</div>
              </span>
            </div>
            <div className={classes.pdfInfoRight}>
              <div className={classes.pdfInfoRightWrapper}>
                {/*  <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-P </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.piecePercentage}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-İ </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.workerPercentage}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-K </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.KDVPercentage}</span>
                </span> */}
                <span className={classes.pdfInfoRightItem}>
                  <span className={classes.pdfInfoRightHeader}>İSKONTO TUTARI </span>
                  <span className={classes.pdfInfoRightValue}>
                    {numberWithCommas(
                      Math.round(
                        (sumValuesofOrder * (initWorkOrder.percentages.piecePercentage / 100) + sumValuesofWork * (initWorkOrder.percentages.workerPercentage / 100)) *
                          currencyValue *
                          100
                      ) / 100
                    )}
                  </span>
                </span>
                <span className={classes.pdfInfoRightItem}>
                  <span className={classes.pdfInfoRightHeader}>FATURA TARİH </span>
                  <span className={classes.pdfInfoRightValue}>{moment(currentDate).format("DD/MM/YYYY")}</span>
                </span>
                <span className={classes.pdfInfoRightItem}>
                  <span className={classes.pdfInfoRightHeader}>VADE </span>
                  <span className={classes.pdfInfoRightValue}>{initWorkOrder.paymentExpiration}</span>
                </span>
                <span className={classes.pdfInfoRightItem}>
                  <span className={classes.pdfInfoRightHeader}>ÖDEME TARİHİ </span>
                  <span className={classes.pdfInfoRightValue}>{moment(currentDate).add(initWorkOrder.paymentExpiration, "days").format("DD/MM/YYYY")}</span>
                </span>
                {/*   <span className="pdfInfoRightSum">
                  <span className="pdfInfoRightSumHeader">TOPLAM </span>
                  <span className="pdfInfoRightSumValue">
                    {" "}
                    {Math.round(
                      (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                        sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                        ((orderToChange.percentages.KDVPercentage + 100) / 100) *
                        100
                    ) / 100}{" "}
                    {orderCardContent.currency === "EUR" ? <>€</> : <>₺</>}
                  </span>
                </span> */}
              </div>
            </div>
          </div>
          <div className={classes.pdftableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={classes.pdfthDescription}>Açıklama</th>
                  <th className={classes.pdfthAmount}>Adet</th>
                  <th className={classes.pdftableBorder}>Birim Liste</th>
                  <th className={classes.pdftableBorder}>Birim Fiyat</th>
                  <th className={classes.pdftableBorder}>Toplam Fiyat</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {initWorkOrder.productsToBeSold.map((workOrder, index) => {
                    return (
                      <tr key={index}>
                        <td className={classes.pdftdDescription}>
                          {workOrder.productCode} - {workOrder.productName}{" "}
                          {products.find((prod) => prod._productId === workOrder.product._productId)?.shelf
                            ? `- ${products.find((prod) => prod._productId === workOrder.product._productId)?.shelf}`
                            : ""}
                        </td>
                        <td>{workOrder.toBeSoldAmount}</td>
                        <td>{numberWithCommas(Math.round(workOrder.product.pricePerAmount * currencyValue * 100) / 100)}</td>
                        <td>
                          {numberWithCommas(Math.round(workOrder.product.pricePerAmount * ((100 - initWorkOrder.percentages.piecePercentage) / 100) * currencyValue * 100) / 100)}
                        </td>
                        <td>
                          {numberWithCommas(
                            Math.round(
                              workOrder.product.pricePerAmount * ((100 - initWorkOrder.percentages.piecePercentage) / 100) * workOrder.toBeSoldAmount * currencyValue * 100
                            ) / 100
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {initWorkOrder.workToBeSold.map((workOrder, index) => {
                    return (
                      <tr key={index}>
                        <td className="pdftdDescription">
                          {workOrder.workCode} - {workOrder.description}
                        </td>
                        <td>{workOrder.workingHours}</td>
                        <td>{numberWithCommas(Math.round(workOrder.workPrice * currencyValue * 100) / 100)}</td>
                        <td>{numberWithCommas(Math.round(workOrder.workPrice * ((100 - initWorkOrder.percentages.piecePercentage) / 100) * currencyValue * 100) / 100)}</td>
                        <td>
                          {numberWithCommas(
                            Math.round(workOrder.workPrice * ((100 - initWorkOrder.percentages.piecePercentage) / 100) * currencyValue * workOrder.workingHours * 100) / 100
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </table>
          </div>

          <div className={classes.pdfBottomInfoWrapper}>
            <div style={{ display: "flex" }}>
              {/*               {orderCardContent.description}
               */}
              <div>
                <div className={classes.pdfnoteInfo}>
                  NOT TL OLARAK YAPILACAK ÖDEMELERDE AYNI GÜNÜN <br /> K.K.T.C MERKEZ BANKASI EFEKTİF SATIŞ KURU GEÇERLİDİR.
                </div>
                <div>
                  <span>
                    <span className={classes.pdfbillIBAN}>GARANTİ BANKASI TL </span>
                    <span>TR95 0006 2001 5890 0006 2994 61</span>
                  </span>
                </div>
                <div>
                  <span>
                    <span className={classes.pdfbillIBAN}>GARANTİ BANKASI EURO</span>
                    <span>TR55 0006 2001 5890 0009 0983 40</span>
                  </span>
                </div>
              </div>
              <span className={classes.pdfcalcWrapper}>
                <span>
                  <div>
                    <span className={classes.pdfcalcHeader}>TOPLAM </span>
                    <span>
                      {numberWithCommas(
                        Math.round(
                          (sumValuesofOrder * ((100 - initWorkOrder.percentages.piecePercentage) / 100) +
                            sumValuesofWork * ((100 - initWorkOrder.percentages.workerPercentage) / 100)) *
                            currencyValue *
                            100
                        ) / 100
                      )}
                      {initWorkOrder.currency === "EUR" ? <>€</> : <>₺</>}
                    </span>
                  </div>
                  <div className={classes.pdfcalculationEnd}>
                    <span className={classes.pdfcalcHeader}>KDV %{initWorkOrder.percentages.KDVPercentage}</span>
                    <span>
                      {numberWithCommas(
                        Math.round(
                          (sumValuesofOrder * ((100 - initWorkOrder.percentages.piecePercentage) / 100) +
                            sumValuesofWork * ((100 - initWorkOrder.percentages.workerPercentage) / 100)) *
                            (initWorkOrder.percentages.KDVPercentage / 100) *
                            currencyValue *
                            100
                        ) / 100
                      )}
                      {initWorkOrder.currency === "EUR" ? <>€</> : <>₺</>}
                    </span>
                  </div>
                  <div style={{ fontWeight: 600 }}>
                    <span className={classes.pdfcalcHeader}>TOPLAM </span>
                    <span>
                      {Math.round(
                        (sumValuesofOrder * ((100 - initWorkOrder.percentages.piecePercentage) / 100) +
                          sumValuesofWork * ((100 - initWorkOrder.percentages.workerPercentage) / 100)) *
                          ((initWorkOrder.percentages.KDVPercentage + 100) / 100) *
                          currencyValue *
                          100
                      ) / 100}
                      {initWorkOrder.currency === "EUR" ? <>€</> : <>₺</>}
                    </span>
                  </div>
                </span>
              </span>
            </div>

            <span className={classes.pdfsignatureWrapper}>
              <span>
                <div>Teslim eden</div>
                <div>Serdar Özkan</div>
              </span>
              <span>
                Teslim Alan
                {/*                 <div className="pdfsignature" />
                 */}{" "}
              </span>
            </span>
          </div>
        </>
      ) : null}
    </div>
  );

  return (
    <div className={classes.AddPopUpBg}>
      {errorShown.opened ? <ErrorMessage message={errorShown.text} onClose={() => setErrorShown({ ...errorShown, opened: false })} /> : null}
      <div className={classes.AddPopUpWorkOrderWrapper}>
        <div className={classes.AddPopUpHeader}>İş Emri Bilgilerini Giriniz</div>

        <div className={classes.AddWorkOrderPopUpContent}>
          <div className={classes.WorkOrderPopUpLeftWrapper}>
            <div className={classes.WorkOrderPopUpHeader}>Bilgiler</div>

            <div className={classes.WorkOrderSelectWrapper}>
              <div className={classes.workOrderSelectHeader}>İş Emri Açıklaması:</div>
              <input
                type="text"
                value={initWorkOrder.description}
                className={classes.workOrderPopUpDescInfoInput}
                onChange={(event) => setInitWorkOrder({ ...initWorkOrder, description: event.target.value })}
              />
            </div>
            <div className={classes.WorkOrderSelectWrapper}>
              <div className={classes.workOrderSelectHeader}>Faturada Görüntülenecek Para Birimini Seçiniz:</div>
              <input type="checkbox" checked={initWorkOrder.currency === "EUR"} id="EUR" onChange={() => setInitWorkOrder({ ...initWorkOrder, currency: "EUR" })} />
              <label htmlFor="EUR">EUR</label>

              <input type="checkbox" checked={initWorkOrder.currency === "TL"} id="TL" onChange={() => setInitWorkOrder({ ...initWorkOrder, currency: "TL" })} />
              <label htmlFor="TL">TL</label>
            </div>

            <div className={classes.WorkOrderSelectWrapper}>
              <div className={classes.workOrderSelectHeader}>İş Emri Durumunu Seçiniz: </div>

              <input
                type="checkbox"
                checked={initWorkOrder.status === "OPEN"}
                id="OPEN"
                onChange={() => {
                  setInitWorkOrder({ ...initWorkOrder, status: "OPEN" });
                }}
              />
              <label htmlFor="OPEN">Açık</label>

              <input
                type="checkbox"
                disabled={workOrdertoOperate?.status === "OPEN"}
                checked={initWorkOrder.status === "OFFER"}
                id="OFFER"
                onChange={() => setInitWorkOrder({ ...initWorkOrder, status: "OFFER" })}
              />
              <label htmlFor="OFFER">Teklif</label>
            </div>
            <div className={classes.WorkOrderPopUpHeader}>Makine Seçimi</div>

            <ReactSelect
              className={classes.WorkOrderPopUpDropDown}
              value={{ value: initWorkOrder.Machine, label: initWorkOrder.Machine.modal.toUpperCase() }}
              options={machines.map((machine) => {
                return { value: machine, label: machine.modal.toUpperCase() };
              })}
              onChange={handleSelectedMachineChange}
              placeholder="Makineyi Seçiniz..."
            />
            <div className={classes.WorkOrderSelectWrapper}>
              <div className={classes.workOrderSelectHeader}>Makine Çalışma Saati:</div>
              <input
                type="number"
                min={0}
                value={initWorkOrder.hoursWorkedOnMachine}
                className={classes.workOrderPopUpInfoInput}
                onChange={(event) => {
                  if (!isNaN(parseInt(event.target.value))) {
                    setInitWorkOrder({ ...initWorkOrder, hoursWorkedOnMachine: parseInt(event.target.value) });
                  } else {
                    setInitWorkOrder({ ...initWorkOrder, hoursWorkedOnMachine: 0 });
                  }
                }}
              />
            </div>

            <div className={classes.WorkOrderPopUpHeader}>Parçalar</div>
            <ReactSelect
              className={classes.WorkOrderPopUpDropDown}
              options={products
                .filter((product) => product.productEntries.some((entry) => entry.show))
                .map((product) => {
                  return {
                    value: product,
                    label: ` ${product.name.toUpperCase()} : ${product.code.toUpperCase()} ${product.note ? `: ${product.note}` : ""} ${
                      product.shelf ? `: ${product.shelf}` : ""
                    } `,
                  };
                })}
              onInputChange={(e) => {
                searchProducts(e);
              }}
              noOptionsMessage={() => {
                if (productsLoading) {
                  return "Yükleniyor...";
                }
                return "Ürün bulunamadı";
              }}
              onChange={handleSelectedProductChange}
              placeholder="Arama yapabilmek için en az 3 karakter giriniz"
            />
            {selectedProduct ? (
              <ReactSelect
                className={classes.WorkOrderPopUpDropDown}
                options={selectedProduct.value.productEntries
                  .filter((prod) => prod.show)
                  .map((productEntry) => {
                    return {
                      value: { toBeSoldAmount: 1, productCode: selectedProduct.value.code, productName: selectedProduct.value.name, product: productEntry },
                      label: `  ${selectedProduct.value.code.toUpperCase()} : Fiyat: ${productEntry.pricePerAmount} : Adet: ${productEntry.currentAmount} ${
                        selectedProduct.value.shelf ? `: Raf: ${selectedProduct.value.shelf}` : ""
                      }`,
                    };
                  })}
                isOptionDisabled={(option) => {
                  if (initWorkOrder.productsToBeSold.some((product) => option.value.product._prodEntryId === product.product._prodEntryId)) {
                    return true;
                  }
                  if (option.value.product.currentAmount <= 0) {
                    return true;
                  }
                  return false;
                }}
                onChange={handleSelectedProductEntryChange}
                placeholder="Ürün Girişleri Seçiniz"
              />
            ) : null}

            <div className={classes.WorkOrderProductItemWrapper}>
              {initWorkOrder.productsToBeSold.map((product, index) => (
                <div key={index} className={classes.AddWorkOrderProductItem}>
                  <input
                    type="number"
                    min={1}
                    value={product.toBeSoldAmount}
                    onChange={(event) => {
                      setInitWorkOrder({
                        ...initWorkOrder,
                        productsToBeSold: initWorkOrder.productsToBeSold.map((productToBeSold) => {
                          if (productToBeSold.product._prodEntryId === product.product._prodEntryId) {
                            if (productToBeSold.product.currentAmount < parseInt(event.target.value)) {
                              setErrorShown({ opened: true, text: "Stokta bu üründen daha fazla adet bulunmamakta!" });
                              return productToBeSold;
                            }
                            if (productToBeSold.product.currentAmount === parseInt(event.target.value)) {
                              setErrorShown({ opened: true, text: "Bu ürünün stokta sonuncusunu kullandınız!" });
                              return { ...productToBeSold, toBeSoldAmount: parseInt(event.target.value) };
                            }
                            if (productToBeSold.product.minAmount !== 0 && productToBeSold.product.minAmount === product.product.currentAmount - parseInt(event.target.value)) {
                              setErrorShown({ opened: true, text: "Ürün minimum adet limitine ulaştı, en yakın zamanda ekleme yapınız!" });
                              return { ...productToBeSold, toBeSoldAmount: parseInt(event.target.value) };
                            } else {
                              return { ...productToBeSold, toBeSoldAmount: parseInt(event.target.value) };
                            }
                          } else {
                            return productToBeSold;
                          }
                        }),
                      });
                    }}
                    className={classes.AddWorkOrderProductAmount}
                  />
                  <div className={classes.WorkOrderPopUpItemName}>
                    {product.productCode} : {product.productName}{" "}
                    {products.find((prod) => prod._productId === product.product._productId)?.shelf
                      ? `: ${products.find((prod) => prod._productId === product.product._productId)?.shelf}`
                      : null}
                    {/*                     {workOrdertoOperate || initWorkOrder.status === "OFFER"
                      ? ensure(
                          products
                            .find((prod) => prod.productEntries.some((entry) => entry._prodEntryId === product.product._prodEntryId))
                            ?.productEntries.find((entry) => entry._prodEntryId === product.product._prodEntryId)?.currentAmount
                        )
                      : ensure(
                          products
                            .find((prod) => prod.productEntries.some((entry) => entry._prodEntryId === product.product._prodEntryId))
                            ?.productEntries.find((entry) => entry._prodEntryId === product.product._prodEntryId)?.currentAmount
                        ) - product.toBeSoldAmount} */}
                    <svg
                      onClick={() => {
                        setInitWorkOrder({
                          ...initWorkOrder,
                          productsToBeSold: initWorkOrder.productsToBeSold.filter((productToBeSold) => productToBeSold.product._prodEntryId !== product.product._prodEntryId),
                        });
                      }}
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="var(--background-color)"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.WorkOrderPopUpHeader}>İşçilik</div>

            <div>
              <div>
                <div className={classes.workEntryHeader}>Açıklama</div>
                <input
                  type="text"
                  placeholder="ör: Bakım İşçiliği"
                  className={classes.workEntryDescriptionValue}
                  value={workEntry.description}
                  onChange={(event) => {
                    setWorkEntry({ ...workEntry, description: event.target.value });
                  }}
                />
              </div>
              <div className={classes.workEntriesWrapper}>
                <div className={classes.workEntryItem}>
                  <div className={classes.workEntryHeader}>İşçilik Kodu</div>
                  <input
                    className={classes.workEntryValue}
                    type="text"
                    value={workEntry.workCode}
                    onChange={(event) => {
                      setWorkEntry({ ...workEntry, workCode: event.target.value });
                    }}
                  />
                </div>
                <div className={classes.workEntryItem}>
                  <div className={classes.workEntryHeader}>İşçilik Ücreti</div>
                  <input
                    className={classes.workEntryValue}
                    type="number"
                    step=".01"
                    value={workEntry.workPrice}
                    onChange={(event) => {
                      if (!isNaN(parseFloat(event.target.value))) {
                        setWorkEntry({ ...workEntry, workPrice: parseFloat(event.target.value) });
                      } else {
                        setWorkEntry({ ...workEntry, workPrice: 0 });
                      }
                    }}
                  />
                </div>
                <div className={classes.workEntryItem}>
                  <div className={classes.workEntryHeader}>İşçilik Saati</div>
                  <input
                    type="number"
                    className="workEntryValue"
                    value={workEntry.workingHours}
                    onChange={(event) => {
                      if (!isNaN(parseInt(event.target.value))) {
                        setWorkEntry({ ...workEntry, workingHours: parseInt(event.target.value) });
                      } else {
                        setWorkEntry({ ...workEntry, workingHours: 0 });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.addWorkOrderBtnWrapper}>
              <button
                onClick={() => {
                  setInitWorkOrder({ ...initWorkOrder, workToBeSold: [...initWorkOrder.workToBeSold, workEntry] });

                  setWorkEntry(initWorkEntry);
                }}
                className={classes.addWorkOrderBtn}
                disabled={workEntry.description === "" || workEntry.workPrice === 0 || workEntry.workingHours === 0}
              >
                İşçilik Ekle (Opsiyonel)
              </button>{" "}
            </div>

            <div>
              {initWorkOrder.workToBeSold.length > 0 ? (
                <div>
                  <div className={classes.WorkOrderPopUpHeader}>Ekli İşçilikler</div>
                  {initWorkOrder.workToBeSold.map((workToBeSold, index) => {
                    return (
                      <div className={classes.addedWorkEntryWithIconsWrapper} key={index}>
                        <div className={classes.addedWorkEntryWrapper}>
                          <div>
                            <div className={classes.addedWorkEntryHeader}>Açıklama</div>
                            <div>{workToBeSold.description}</div>
                          </div>
                          <div>
                            <div className={classes.addedWorkEntryHeader}>İşçilik Kodu</div>

                            <div>{workToBeSold.workCode}</div>
                          </div>
                          <div>
                            <div className={classes.addedWorkEntryHeader}>İşçilik Ücreti</div>

                            <div>{workToBeSold.workPrice}</div>
                          </div>

                          <div>
                            <div className={classes.addedWorkEntryHeader}>İşçilik Saati</div>
                            <div>{workToBeSold.workingHours}</div>
                          </div>
                        </div>
                        <div className={classes.workEntryIconsWrapper}>
                          <div
                            onClick={() => {
                              setWorkEntry(workToBeSold);
                              setInitWorkOrder({ ...initWorkOrder, workToBeSold: initWorkOrder.workToBeSold.filter((_wt, filterIndex) => filterIndex !== index) });
                            }}
                          >
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="#f37826" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.81 6.94L11.06 3.19L2 12.25V16H5.75L14.81 6.94ZM4 14V13.08L11.06 6.02L11.98 6.94L4.92 14H4Z" />
                              <path d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04Z" />
                              <path d="M20 18H0V22H20V18Z" />
                            </svg>
                          </div>
                          <div
                            onClick={() => {
                              setInitWorkOrder({ ...initWorkOrder, workToBeSold: initWorkOrder.workToBeSold.filter((_wt, filterIndex) => filterIndex !== index) });
                            }}
                          >
                            <svg width="14" height="18" viewBox="0 0 14 18" fill="red" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>

          <div className={classes.WorkOrderPopUpRightWrapper}>{exportComponent}</div>
        </div>

        <span className={classes.buttonWrapper}>
          <button
            className={classes.addBtn}
            disabled={initWorkOrder.description === "" || initWorkOrder.Machine.brand === "" || (initWorkOrder.productsToBeSold.length || initWorkOrder.workToBeSold.length) === 0}
            onClick={() => {
              if (createdWorkOrder) {
                createdWorkOrder(initWorkOrder);
              } else if (updatedWorkOrder) {
                updatedWorkOrder(initWorkOrder);
              }

              closed(true);
            }}
          >
            {createdWorkOrder ? <>İş Emrini Ekle</> : <>İş Emrini Düzenle</>}
          </button>
          <button
            className={classes.cancelBtn}
            onClick={() => {
              closed(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
