import React, { useState } from "react";
import { ProductEntries } from "../../../Models/ProductEntries";
import "./EditProductPopUp.scss";

interface IEditProductPopUp {
  propsProductEntry?: ProductEntries;
  editedProductEntry: (productEntry: ProductEntries) => void;
  close: (closed: boolean) => void;
}

const currentDate = new Date();

export const EditProductPopUp: React.FC<IEditProductPopUp> = ({ editedProductEntry, close, propsProductEntry }) => {
  const initState = () => {
    return (
      propsProductEntry ?? {
        boughtPriceAmount: 0,
        boughtAmount: 0,
        customs: 0,
        KKTC_KDV: 0,
        salesListPercentage: 60,
        stopaj: 0,
        pricePerAmount: 0,
        date: currentDate,
        currentAmount: 0,
        _prodEntryId: "",
        _productId: 0,
        show: true,
      }
    );
  };

  const [initProductEntry, setInitProductEntry] = useState<ProductEntries>(initState());

  return (
    <div className="AddPopUpBg">
      <div className="AddPopUpEditProductWrapper">
        <div className="AddPopUpHeader">Ürün Giriş Bilgilerini Giriniz</div>

        <div className="AddPopUpEditProductContent">
          <span>
            Alınan Adet:
            <input
              className="ProductInput"
              placeholder="Alınan Adeti giriniz..."
              defaultValue={initProductEntry.boughtAmount}
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(parseInt(e.target.value))) {
                  if (propsProductEntry) {
                    setInitProductEntry({
                      ...initProductEntry,
                      boughtAmount: parseInt(e.target.value),
                      currentAmount: propsProductEntry.currentAmount + parseInt(e.target.value) - propsProductEntry.boughtAmount,
                    });
                  } else {
                    setInitProductEntry({
                      ...initProductEntry,
                      boughtAmount: parseInt(e.target.value),
                      currentAmount: parseInt(e.target.value),
                    });
                  }
                } else {
                  if (propsProductEntry) {
                    setInitProductEntry({
                      ...initProductEntry,
                      boughtAmount: 0,
                    });
                  } else {
                    setInitProductEntry({
                      ...initProductEntry,
                      boughtAmount: 0,
                      currentAmount: 0,
                    });
                  }
                }
              }}
            />
          </span>
          <span>
            Birim Giriş Fiyatı:
            <input
              step=".01"
              className="ProductInput"
              placeholder="Giriş Fiyatı giriniz..."
              type="number"
              defaultValue={initProductEntry.boughtPriceAmount}
              onChange={(e) => {
                if (!Number.isNaN(parseFloat(e.target.value))) {
                  setInitProductEntry({
                    ...initProductEntry,
                    boughtPriceAmount: parseFloat(e.target.value),
                    pricePerAmount:
                      Math.round(
                        (parseFloat(e.target.value) + initProductEntry.customs) /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ *
                          ((100 + initProductEntry.salesListPercentage) / 100) *
                          100
                      ) / 100,
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    boughtPriceAmount: 0,
                    pricePerAmount:
                      Math.round(
                        initProductEntry.customs /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ * ((100 + initProductEntry.salesListPercentage) / 100) * 100
                      ) / 100,
                  });
                }
              }}
            />
          </span>

          <span>
            Birim Gümrük ve Masraflar:
            <input
              step=".01"
              className="ProductInput"
              placeholder="Gümrük Ücreti giriniz..."
              type="number"
              defaultValue={initProductEntry.customs}
              onChange={(e) => {
                if (!Number.isNaN(parseFloat(e.target.value))) {
                  setInitProductEntry({
                    ...initProductEntry,
                    customs: parseFloat(e.target.value),
                    pricePerAmount:
                      Math.round(
                        (initProductEntry.boughtPriceAmount + parseFloat(e.target.value)) /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ *
                          ((100 + initProductEntry.salesListPercentage) / 100) *
                          100
                      ) / 100,
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    customs: 0,
                    pricePerAmount:
                      Math.round(
                        initProductEntry.boughtPriceAmount /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ * ((100 + initProductEntry.salesListPercentage) / 100) * 100
                      ) / 100,
                  });
                }
              }}
            />
          </span>

          {/*   <span>
            Birim Stopaj Ücreti:
            <input
              step=".01"
              defaultValue={initProductEntry.stopaj}
              className="ProductInput"
              placeholder="Stopaj Ücretini giriniz..."
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(parseFloat(e.target.value))) {
                  setInitProductEntry({
                    ...initProductEntry,
                    stopaj: parseFloat(e.target.value),
                     pricePerAmount:
                      Math.round(
                        (initProductEntry.boughtPriceAmount + initProductEntry.customs + initProductEntry.KKTC_KDV + parseFloat(e.target.value)) *
                          ((100 + initProductEntry.salesListPercentage) / 100) *
                          ((100 + initProductEntry.stockBalanceAmount) / 100) *
                          100
                      ) / 100, 
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    stopaj: 0,
                      pricePerAmount:
                      Math.round(
                        (initProductEntry.boughtPriceAmount + initProductEntry.customs + initProductEntry.KKTC_KDV) *
                          ((100 + initProductEntry.stockBalanceAmount) / 100) *
                          ((100 + initProductEntry.salesListPercentage) / 100) *
                          100
                      ) / 100, 
                  });
                }
              }}
            />
          </span> */}
          <span>
            Birim KKTC KDV:
            <input
              step=".01"
              className="ProductInput"
              defaultValue={initProductEntry.KKTC_KDV}
              placeholder="KKTC Vergi Ücretini giriniz..."
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(parseFloat(e.target.value))) {
                  setInitProductEntry({
                    ...initProductEntry,
                    KKTC_KDV: parseFloat(e.target.value),
                    /* pricePerAmount:
                      Math.round(
                        (initProductEntry.boughtPriceAmount + initProductEntry.customs + parseFloat(e.target.value) + initProductEntry.stopaj) *
                          ((100 + initProductEntry.stockBalanceAmount) / 100) *
                          ((100 + initProductEntry.salesListPercentage) / 100) *
                          100
                      ) / 100, */
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    KKTC_KDV: 0,
                    /*  pricePerAmount:
                      Math.round(
                        (initProductEntry.boughtPriceAmount + initProductEntry.customs + initProductEntry.stopaj) *
                          ((100 + initProductEntry.stockBalanceAmount) / 100) *
                          ((100 + initProductEntry.salesListPercentage) / 100) *
                          100
                      ) / 100, */
                  });
                }
              }}
            />
          </span>

          <span>
            Birim Satış Liste Yüzdesi:
            <input
              className="ProductInput"
              placeholder="Yüzde giriniz..."
              defaultValue={initProductEntry.salesListPercentage}
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(parseFloat(e.target.value))) {
                  setInitProductEntry({
                    ...initProductEntry,
                    salesListPercentage: parseFloat(e.target.value),
                    pricePerAmount:
                      Math.round(
                        (initProductEntry.boughtPriceAmount + initProductEntry.customs) /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ *
                          ((100 + parseFloat(e.target.value)) / 100) *
                          100
                      ) / 100,
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    salesListPercentage: 0,
                    pricePerAmount:
                      Math.round((initProductEntry.boughtPriceAmount + initProductEntry.customs) /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ * 100) / 100,
                  });
                }
              }}
            />
          </span>
        </div>
        {/* <div className="optionalsProductWrapper">
          <span>
            Minimum Adet:
            <input
              className="ProductInput"
              placeholder="Minimum Adeti giriniz..."
              defaultValue={initProductEntry.minAmount}
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(parseInt(e.target.value))) {
                  setInitProductEntry({
                    ...initProductEntry,
                    minAmount: parseInt(e.target.value),
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    minAmount: undefined,
                  });
                }
              }}
            />
          </span>
          <span>
            Raf:
            <input
              className="ProductInput"
              placeholder="Ürünün Rafını giriniz..."
              defaultValue={initProductEntry.shelf}
              type="text"
              onChange={(e) => {
                if (e.target.value !== "") {
                  setInitProductEntry({
                    ...initProductEntry,
                    shelf: e.target.value,
                  });
                } else {
                  setInitProductEntry({
                    ...initProductEntry,
                    shelf: undefined,
                  });
                }
              }}
            />
          </span>
        </div> */}
        <span className="EditProductInfoWrapper">
          <span className="EditProductInfo">
            Birim Sabit Fiyat:
            {Math.round((initProductEntry.boughtPriceAmount + initProductEntry.customs) /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ * 100) / 100}
          </span>
          <span className="EditProductInfo">
            Birim Liste Fiyatı:
            {Math.round(
              (initProductEntry.boughtPriceAmount + initProductEntry.customs) /* + initProductEntry.KKTC_KDV + initProductEntry.stopaj */ *
                ((100 + initProductEntry.salesListPercentage) / 100) *
                100
            ) / 100}
          </span>
        </span>

        <span className="buttonWrapper">
          <button
            disabled={initProductEntry.boughtAmount === 0 || initProductEntry.boughtPriceAmount === 0}
            className="addBtn"
            onClick={() => {
              close(true);
              editedProductEntry(initProductEntry);
            }}
          >
            Ürüne Ekleme Yap
          </button>
          <button
            className="cancelBtn"
            onClick={() => {
              close(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
