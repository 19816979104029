import "./App.scss";
import { AppRouter } from "./Router/AppRouter";

function App() {
  return (
    <div className="App">
      {/* <div className="MaintenanceWraper">
        <h1>Bakımdayız... Lütfen Daha Sonra Tekrar Deneyiniz</h1>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <ThreeCircles height="80" width="80" color="var(--primary-color)" />
        </div>
      </div> */}
      <AppRouter />
    </div>
  );
}

export default App;
