import React, { useState } from "react";
import "./Machines.scss";
import { AddMachinePopUp } from "../PopUps/AddMachinePopUp/AddMachinePopUp";
import { Customer } from "../../Models/Customer";

interface IMachines {
  customer: Customer;
  handleCreatedMachine: (customer: Customer) => void;
}

export const Machines: React.FC<IMachines> = ({ customer, handleCreatedMachine }) => {
  /*   const [selectedMachine, setSelectedMachine] = useState<Machine>();
  const [deleteClicked, setdeleteClicked] = useState<boolean>(false); */
  const [addClicked, setAddClicked] = useState<boolean>(false);
  const [editClicked, setEditClicked] = useState<{ clicked: boolean; index: number }>({ clicked: false, index: -1 });

  /*   const removeMachine = () => {
    if (selectedMachine) {
      setIsLoading(true);
      customer.machines.splice(customer.machines.indexOf(selectedMachine), 1);
      api.putCustomer(customer).finally(() => setIsLoading(false));
    }
  }; */

  return (
    <div className="machinesWrapper">
      <div className="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr>
              <th>Marka</th>
              <th>Model</th>
              <th>Seri</th>
              <th>Ürün Kodu</th>
              <th>Q Kod</th>
              <th>Yılı</th>
              <th>Düzenle</th>
            </tr>
          </thead>
          <tbody>
            {customer.machines.length === 0 ? (
              <h3>Bu müşterinin henüz makinesi bulunmamaktadır.</h3>
            ) : (
              customer.machines
                .sort((a: any, b: any) => {
                  return a.brand.localeCompare(b.brand);
                })
                .map((machine, index) => (
                  <tr key={index}>
                    <td>{machine.brand}</td>
                    <td>{machine.modal}</td>
                    <td>{machine.seriesNumber}</td>
                    <td>{machine.productNumber}</td>
                    <td>{machine.qCode}</td>
                    <td>{machine.year}</td>
                    <td>
                      <button className="stockCardIconsWrapper">
                        <div
                          onClick={() => {
                            setEditClicked({ clicked: true, index: index });
                          }}
                        >
                          <svg width="20" height="22" viewBox="0 0 20 22" fill="#f37826" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.81 6.94L11.06 3.19L2 12.25V16H5.75L14.81 6.94ZM4 14V13.08L11.06 6.02L11.98 6.94L4.92 14H4Z" />
                            <path d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04Z" />
                            <path d="M20 18H0V22H20V18Z" />
                          </svg>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
      {/*   <Swiper className="swiperWrapper" slidesPerView={2}>
        {customer.machines.map((machine: Machine, index: number) => (
          <SwiperSlide key={index}>
            <div onClick={() => setSelectedMachine(machine)} className="machineItem">
              <input type="checkbox" id="30" checked={selectedMachine === machine} />
              <label htmlFor="WorkOrders">
                <span>
                  <div className="machineField">Marka: {machine.brand}</div>
                  <div className="machineField">Model: {machine.modal}</div>
                  <div className="machineField">Seri: {machine.seriesNumber}</div>
                </span>
                <span>
                  <div className="machineField">Ürün: {machine.productNumber}</div>
                  <div className="machineField">Q Kod: {machine.qCode}</div>
                  <div className="machineField">Yıl: {machine.year}</div>
                </span>
              </label>
            </div>
          </SwiperSlide>
        ))}
      </Swiper> */}

      <div className="machineBtnWrapper">
        <button className="machineAddBtn" onClick={() => setAddClicked(true)}>
          Makine Kaydı Ekle
        </button>
        {/*  {selectedMachine !== undefined ? (
          <button className="machineDeleteBtn" onClick={() => setdeleteClicked(true)}>
            Makine Kaydını Sil
          </button>
        ) : null} */}
      </div>

      {/*  {deleteClicked ? (
        <div>
          <DeletePopUp
            header="Makine kaydını silmek istediğinizden emin misiniz?"
            onClick={() => {
              removeMachine();
            }}
            close={(closed) => {
              setdeleteClicked(!closed);
            }}
          />
        </div>
      ) : null} */}
      {addClicked ? (
        <div>
          <AddMachinePopUp
            customerId={customer._customerId}
            close={(closed) => setAddClicked(!closed)}
            createdMachine={(machine) => {
              handleCreatedMachine({ ...customer, machines: [...customer.machines, machine] });
            }}
          />
        </div>
      ) : null}
      {editClicked.clicked ? (
        <AddMachinePopUp
          customerId={customer._customerId}
          close={(closed) => setEditClicked({ clicked: !closed, index: -1 })}
          createdMachine={(machine) => {
            handleCreatedMachine({
              ...customer,
              machines: customer.machines.map((mappedMachine, i) => {
                if (i === editClicked.index) {
                  return machine;
                }
                return mappedMachine;
              }),
            });
          }}
          machinetoBeEdited={customer.machines[editClicked.index]}
        />
      ) : null}
    </div>
  );
};
