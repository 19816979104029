import { useState } from "react";
import API from "../api/API";
import { CustomerDetails } from "../Models/CustomerDetails";
import { Customer } from "../Models/Customer";

const api = new API();

const useCustomer = () => {
  const [customersLoading, setCustomersLoading] = useState<boolean>(false);

  const searchCustomers = (page: number, limit: number, searchTerm: string) => {
    setCustomersLoading(true);
    return api
      .getCustomers(page, limit, searchTerm)
      .then((customers) => {
        return customers;
      })
      .finally(() => setCustomersLoading(false))
      .catch((err) => console.error(err));
  };

  const getCustomerDetails = (customerId: number) => {
    setCustomersLoading(true);
    return api
      .getCustomerDetails(customerId)
      .then((customerDetails) => {
        return customerDetails;
      })
      .finally(() => setCustomersLoading(false))
      .catch((err) => console.error(err));
  };

  const updateCustomerDetails = (customerDetails: CustomerDetails) => {
    setCustomersLoading(true);
    return api
      .putCustomerDetails(customerDetails)
      .then((customerDetails) => {
        return customerDetails;
      })
      .finally(() => setCustomersLoading(false))
      .catch((err) => console.error(err));
  };

  const updateCustomer = (customer: Customer) => {
    setCustomersLoading(true);
    return api
      .putCustomer(customer)
      .then((customer) => {
        return customer;
      })
      .finally(() => setCustomersLoading(false))
      .catch((err) => console.error(err));
  };

  const addCustomer = (customer: Customer) => {
    setCustomersLoading(true);
    return api
      .postCustomers(customer)
      .then((customer) => {
        return customer;
      })
      .finally(() => setCustomersLoading(false))
      .catch((err) => console.error(err));
  };

  const deleteCustomer = (customer: Customer) => {
    setCustomersLoading(true);
    return api
      .deleteCustomer(customer)
      .then((customer) => {
        return customer;
      })
      .finally(() => setCustomersLoading(false))
      .catch((err) => console.error(err));
  };

  return { searchCustomers, customersLoading, getCustomerDetails, updateCustomer, updateCustomerDetails, addCustomer, deleteCustomer };
};

export default useCustomer;
