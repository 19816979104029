import { PDFExport } from "@progress/kendo-react-pdf";
import { ReactElement, useRef } from "react";
import "./ExportBillPopUp.scss";

interface IExportBillPopUp {
  close: (closed: boolean) => void;
  children: React.ReactNode;
  fileName: string;
  scale?: number;
  sideways?: boolean;
}

export const ExportBillPopUp: React.FC<IExportBillPopUp> = ({ close, children, sideways, scale = 1, fileName }) => {
  const pdfExportComponent = useRef<any>(null);

  const handleExportComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  return (
    <div className="AddPopUpBg">
      <div className="AddPopUpWrapper">
        <div className="AddPopUpHeader">Çıktı Alınacak Fatura</div>

        <div className="AddPopUpContent">
          <PDFExport fileName={fileName} paperSize={sideways ? [842.4, 597.6] : "A4"} scale={scale} ref={pdfExportComponent}>
            {children as ReactElement<any>}
          </PDFExport>
        </div>
        <span className="buttonWrapper">
          <button
            className="addBtn"
            onClick={() => {
              close(true);
              handleExportComponent();
            }}
          >
            PDF olarak al
          </button>
          <button
            className="cancelBtn"
            onClick={() => {
              close(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
