import { useEffect, useState } from "react";
import { WorkOrder } from "../../../Models/WorkOrder";
import "./OrderCard.scss";
import ReactSelect from "react-select";
import { ExportBillPopUp } from "../../PopUps/ExportBillPopUp/ExportBillPopUp";
import moment from "moment";
import { DeletePopUp } from "../../PopUps/DeletePopUp/DeletePopUp";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

interface IOrderCard {
  orderCardContent: WorkOrder;
  updatedCard: (WorkOrder: WorkOrder, justNumberSet?: 0 | 1) => void;
  customerName: string;
  handleEdit: (workOrdeR: WorkOrder) => void;
  handleDelete: (WorkOrder: WorkOrder) => void;
  customerAddress: string;
  customerId: number;
  currency: number;
}

export type RSelectOption = {
  value: string;
  label: string;
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const OrderCard: React.FC<IOrderCard> = ({ orderCardContent, updatedCard, customerAddress, customerName, customerId, handleDelete, handleEdit, currency }) => {
  const [orderToChange, setOrderToChange] = useState<WorkOrder>(orderCardContent);
  const [sumValuesofOrder, setSumValuesOfOrder] = useState<number>(0);
  const [sumValuesofWork, setSumValuesOfWork] = useState<number>(0);
  const [billSelected, setBillSelected] = useState<boolean>(false);

  // -1 = DEACTIVE, 0 = CLOSE REGULARLY, 1 = JUST EDIT BILL NO
  const [warningpopUp, setWarningPopUp] = useState<-1 | 0 | 1>(-1);
  const [currentDate, setCurrentDate] = useState<Date | null>(new Date());

  const [billNumber, setBillNumber] = useState<string | undefined>(orderCardContent.bill ? orderCardContent.bill.billNumber : undefined);

  const handleSelectedOptionChange = (newSelectedOption: any) => {
    setOrderToChange({ ...orderToChange, status: newSelectedOption.value });
  };

  useEffect(() => {
    setOrderToChange(orderCardContent);

    setSumValuesOfOrder(
      orderToChange.productsToBeSold
        .map((item) => {
          return item.product.pricePerAmount * item.toBeSoldAmount;
        })
        .reduce((a, b) => a + b, 0)
    );

    setSumValuesOfWork(
      orderToChange.workToBeSold
        .map((item) => {
          return item.workPrice * item.workingHours;
        })
        .reduce((a, b) => a + b, 0)
    );
  }, [orderCardContent, orderToChange.productsToBeSold, orderToChange.workToBeSold]);

  const exportComponent = (
    <div className="pdfWrapper">
      {orderCardContent !== undefined ? (
        <>
          <div className="pdfInfoWrapper">
            <div>
              <div className="pdfOrderCardcustomerDetailsHeaderWrapper">
                <div className="orderCardcustomerDetailsHeader">{customerName}</div>
                <div className="customerDetailsId">{customerAddress}</div>
              </div>

              <span className="pdfInfoLeftItemsWrapper">
                {/* <div className="pdfInfoLeftItem">
                  <span className="pdfInfoLeftHeader">İŞ EMRİ TARİH </span>
                  <span className="pdfInfoLeftValue">{moment(currentDate).format("DD/MM/YYYY")}</span>
                </div> */}
                <div className="pdfInfoLeftItem">
                  <span className="pdfInfoLeftHeader">İŞ EMRİ NO </span>
                  <span className="pdfInfoLeftValue">{orderCardContent._id}</span>
                </div>
                <div className="pdfInfoLeftItem">
                  <span className="pdfInfoLeftHeader">MAKİNE </span>
                  <span className="pdfInfoLeftValue">
                    {orderCardContent.Machine.brand} - {orderCardContent.Machine.modal}
                  </span>
                </div>
                <div className="pdfInfoLeftItem">
                  <span className="pdfInfoLeftHeader">SAAT </span>
                  <span className="pdfInfoLeftValue">{orderCardContent.hoursWorkedOnMachine}</span>
                </div>
                <div className="pdfStatusWrapper">{orderToChange.status === "OFFER" ? "TEKLİF" : orderToChange.status === "OPEN" ? "AÇIK" : "KAPALI"}</div>
              </span>
            </div>
            <div className="pdfInfoRight">
              <div className="pdfInfoRightWrapper">
                {/*  <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-P </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.piecePercentage}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-İ </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.workerPercentage}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-K </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.KDVPercentage}</span>
                </span> */}
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightHeader">İSKONTO TUTARI </span>
                  <span className="pdfInfoRightValue">
                    {numberWithCommas(
                      Math.round(
                        (sumValuesofOrder * (orderCardContent.percentages.piecePercentage / 100) + sumValuesofWork * (orderCardContent.percentages.workerPercentage / 100)) *
                          currency *
                          100
                      ) / 100
                    )}
                  </span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightHeader">FATURA TARİH </span>
                  <span className="pdfInfoRightValue">{moment(currentDate).format("DD/MM/YYYY")}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightHeader">VADE </span>
                  <span className="pdfInfoRightValue">{orderCardContent.paymentExpiration}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightHeader">ÖDEME TARİHİ </span>
                  <span className="pdfInfoRightValue">{moment(currentDate).add(orderCardContent.paymentExpiration, "days").format("DD/MM/YYYY")}</span>
                </span>
                {/*   <span className="pdfInfoRightSum">
                  <span className="pdfInfoRightSumHeader">TOPLAM </span>
                  <span className="pdfInfoRightSumValue">
                    {" "}
                    {Math.round(
                      (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                        sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                        ((orderToChange.percentages.KDVPercentage + 100) / 100) *
                        100
                    ) / 100}{" "}
                    {orderCardContent.currency === "EUR" ? <>€</> : <>₺</>}
                  </span>
                </span> */}
              </div>
            </div>
          </div>
          <div className="pdftableWrapper">
            <table>
              <thead>
                <tr>
                  <th className="pdfthDescription">Açıklama</th>
                  <th className="pdfthAmount">Adet</th>
                  <th className="pdftableBorder">Birim Liste</th>
                  <th className="pdftableBorder">Birim Fiyat</th>
                  <th className="pdftableBorder">Toplam Fiyat</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {orderCardContent.productsToBeSold.map((workOrder, index) => {
                    return (
                      <tr key={index}>
                        <td className="pdftdDescription">
                          {workOrder.productCode} - {workOrder.productName} {workOrder.product.shelf ?? ""}
                        </td>
                        <td>{workOrder.toBeSoldAmount}</td>
                        <td>{numberWithCommas(Math.round(workOrder.product.pricePerAmount * currency * 100) / 100)}</td>
                        <td>
                          {numberWithCommas(Math.round(workOrder.product.pricePerAmount * ((100 - orderCardContent.percentages.piecePercentage) / 100) * currency * 100) / 100)}
                        </td>
                        <td>
                          {numberWithCommas(
                            Math.round(
                              workOrder.product.pricePerAmount * ((100 - orderCardContent.percentages.piecePercentage) / 100) * workOrder.toBeSoldAmount * currency * 100
                            ) / 100
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {orderCardContent.workToBeSold.map((workOrder, index) => {
                    return (
                      <tr key={index}>
                        <td className="pdftdDescription">
                          {workOrder.workCode} - {workOrder.description}
                        </td>
                        <td>{workOrder.workingHours}</td>
                        <td>{numberWithCommas(Math.round(workOrder.workPrice * currency * 100) / 100)}</td>
                        <td>{numberWithCommas(Math.round(workOrder.workPrice * ((100 - orderCardContent.percentages.piecePercentage) / 100) * currency * 100) / 100)}</td>
                        <td>
                          {numberWithCommas(
                            Math.round(workOrder.workPrice * ((100 - orderCardContent.percentages.piecePercentage) / 100) * currency * workOrder.workingHours * 100) / 100
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </table>
          </div>

          <div className="pdfBottomInfoWrapper">
            <div style={{ display: "flex" }}>
              {/*               {orderCardContent.description}
               */}
              <div>
                <div className="pdfnoteInfo">
                  NOT TL OLARAK YAPILACAK ÖDEMELERDE AYNI GÜNÜN <br /> K.K.T.C MERKEZ BANKASI EFEKTİF SATIŞ KURU GEÇERLİDİR.
                </div>
                <div>
                  <span>
                    <span className="pdfbillIBAN">GARANTİ BANKASI TL </span>
                    <span>TR95 0006 2001 5890 0006 2994 61</span>
                  </span>
                </div>
                <div>
                  <span>
                    <span className="pdfbillIBAN">GARANTİ BANKASI EURO</span>
                    <span>TR55 0006 2001 5890 0009 0983 40</span>
                  </span>
                </div>
              </div>
              <span className="pdfcalcWrapper">
                <span>
                  <div>
                    <span className="pdfcalcHeader">TOPLAM </span>
                    <span>
                      {numberWithCommas(
                        Math.round(
                          (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                            sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                            currency *
                            100
                        ) / 100
                      )}
                      {orderCardContent.currency === "EUR" ? <>€</> : <>₺</>}
                    </span>
                  </div>
                  <div className="pdfcalculationEnd">
                    <span className="pdfcalcHeader">KDV %{orderCardContent.percentages.KDVPercentage}</span>
                    <span>
                      {numberWithCommas(
                        Math.round(
                          (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                            sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                            (orderToChange.percentages.KDVPercentage / 100) *
                            currency *
                            100
                        ) / 100
                      )}
                      {orderCardContent.currency === "EUR" ? <>€</> : <>₺</>}
                    </span>
                  </div>
                  <div style={{ fontWeight: 600 }}>
                    <span className="pdfcalcHeader">TOPLAM </span>
                    <span>
                      {numberWithCommas(
                        Math.round(
                          (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                            sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                            ((orderToChange.percentages.KDVPercentage + 100) / 100) *
                            currency *
                            100
                        ) / 100
                      )}
                      {orderCardContent.currency === "EUR" ? <>€</> : <>₺</>}
                    </span>
                  </div>
                </span>
              </span>
            </div>

            <span className="pdfsignatureWrapper">
              <span>
                <div>Teslim eden</div>
                <div>Serdar Özkan</div>
              </span>
              <span>
                Teslim Alan
                {/*                 <div className="pdfsignature" />
                 */}{" "}
              </span>
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
  return (
    <span className="orderCardButtonsWrapper">
      <span tabIndex={1} className="orderCardWrapper">
        <span className="orderCardLeftWrapper">
          <span>
            <div>
              <h3>İş Emri</h3>
              <h2>{orderCardContent._id}</h2>
            </div>
          </span>
          <span className="orderCardModalDesc">
            <h3>{orderCardContent.Machine.modal}</h3>
            <h3 style={{ fontWeight: "400" }}>{orderCardContent.description}</h3>
          </span>
        </span>
        <span className="orderCardMiddleWrapper">
          <span>
            <div className="orderButtonsWrapper">
              <button className="exportBtn" onClick={() => setBillSelected(true)}>
                PDF olarak çıkart
              </button>
              <button
                disabled={orderCardContent.status === "CLOSED"}
                className="addBtn"
                style={{ backgroundColor: "orange", width: "8rem" }}
                onClick={() => handleEdit(orderCardContent)}
              >
                Düzenle
              </button>
              <button className="deleteBtn" onClick={() => handleDelete(orderCardContent)}>
                Sil
              </button>
            </div>
          </span>
          <span>
            <div>
              <div className="orderCardState">
                <div className="orderCardIcon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill={orderToChange.status === "OFFER" ? "yellow" : orderToChange.status === "OPEN" ? "rgb(187, 1, 1)" : "grey"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" />
                    <path d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z" />
                  </svg>
                </div>

                {warningpopUp !== -1 ? (
                  <DeletePopUp
                    close={() => setWarningPopUp(-1)}
                    onClick={() => {
                      updatedCard(
                        {
                          ...orderToChange,
                          bill: {
                            _customerId: customerId,
                            billAmountSpent:
                              Math.round(
                                (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                                  sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                                  ((orderToChange.percentages.KDVPercentage + 100) / 100) *
                                  100
                              ) / 100,
                            date: currentDate,
                            _id: "",
                            billNumber: billNumber,
                          },
                        },
                        warningpopUp === 1 ? 1 : 0
                      );
                    }}
                    children={
                      <div className="orderCardDatePickerWrapper">
                        <div className="orderCardCloseItem">
                          <h4>Fatura Tarihi Giriniz</h4>
                          <DatePicker
                            value={currentDate === null ? moment(new Date()).format("DD/MM/YYYY") : moment(currentDate).format("DD/MM/YYYY")}
                            className="orderCardDatePicker"
                            placeholderText="Tarih Giriniz..."
                            locale={tr}
                            onChange={(date) => setCurrentDate(date)}
                          />
                        </div>
                        <div className="orderCardCloseItem">
                          <h4>Fatura No Giriniz</h4>
                          <input type="text" placeholder={orderCardContent.bill?.billNumber} onChange={(e) => setBillNumber(e.target.value)} />
                        </div>
                      </div>
                    }
                    header="İş emrini kapatıp fatura oluşturmak üzeresiniz, işleme devam etmek istediğinizden emin misiniz?"
                  />
                ) : null}

                {billSelected ? (
                  <ExportBillPopUp fileName={orderCardContent.description} close={(close) => setBillSelected(!close)}>
                    {exportComponent}
                  </ExportBillPopUp>
                ) : null}
                <ReactSelect
                  isDisabled={orderCardContent.status === "CLOSED"}
                  options={[
                    { value: "OPEN", label: "Açık", disabled: orderCardContent.status === "OPEN" },
                    { value: "CLOSED", label: "Kapalı", disabled: false },
                  ]}
                  onChange={handleSelectedOptionChange}
                  isOptionDisabled={(option) => option.disabled}
                  placeholder={orderToChange.status === "OFFER" ? "TEKLİF" : orderToChange.status === "OPEN" ? "AÇIK" : "KAPALI"}
                />
              </div>
              {orderCardContent.status === "CLOSED" ? (
                <button className="deleteBtn" style={{ backgroundColor: "orange" }} onClick={() => setWarningPopUp(1)}>
                  Fatura No. Değiştir
                </button>
              ) : null}
            </div>
          </span>
        </span>

        <span className="orderCardUpdatableCodesWrapper">
          <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableCodeHeader">KOD-P : </span>
            <input
              className="orderCardUpdatableCodeValue"
              disabled={orderCardContent.status === "CLOSED"}
              onChange={(event) => {
                setOrderToChange({
                  ...orderToChange,
                  percentages: {
                    piecePercentage: parseInt(event.target.value),
                    workerPercentage: orderToChange.percentages.workerPercentage,
                    KDVPercentage: orderToChange.percentages.KDVPercentage,
                  },
                });
              }}
              type="number"
              value={orderToChange.percentages.piecePercentage}
            />
          </span>
          <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableCodeHeader">KOD-İ : </span>
            <input
              disabled={orderCardContent.status === "CLOSED"}
              className="orderCardUpdatableCodeValue"
              onChange={(event) => {
                setOrderToChange({
                  ...orderToChange,
                  percentages: {
                    piecePercentage: orderToChange.percentages.piecePercentage,
                    workerPercentage: parseInt(event.target.value),
                    KDVPercentage: orderToChange.percentages.KDVPercentage,
                  },
                });
              }}
              type="number"
              value={orderToChange.percentages.workerPercentage}
              placeholder={`${orderToChange.percentages.workerPercentage}`}
            />
          </span>
          <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableCodeHeader">KOD-K : </span>
            <input
              disabled={orderCardContent.status === "CLOSED"}
              className="orderCardUpdatableCodeValue"
              onChange={(event) => {
                setOrderToChange({
                  ...orderToChange,
                  percentages: {
                    piecePercentage: orderToChange.percentages.piecePercentage,
                    workerPercentage: orderToChange.percentages.workerPercentage,
                    KDVPercentage: parseInt(event.target.value),
                  },
                });
              }}
              value={orderToChange.percentages.KDVPercentage}
              type="number"
            />
          </span>
          <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableExpHeader">VADE : </span>
            <span className="orderCardUpdatableValue">
              <input
                type="checkbox"
                id={`0-${orderCardContent.description}`}
                disabled={orderCardContent.status === "CLOSED"}
                checked={orderToChange.paymentExpiration === 0}
                onChange={() => setOrderToChange({ ...orderToChange, paymentExpiration: 0 })}
              />
              <label htmlFor={`0-${orderCardContent.description}`}>0</label>
              <input
                type="checkbox"
                id={`30-${orderCardContent.description}`}
                disabled={orderCardContent.status === "CLOSED"}
                checked={orderToChange.paymentExpiration === 30}
                onChange={() => setOrderToChange({ ...orderToChange, paymentExpiration: 30 })}
              />
              <label htmlFor={`30-${orderCardContent.description}`}>30</label>
              <input
                type="checkbox"
                disabled={orderCardContent.status === "CLOSED"}
                id={`60-${orderCardContent.description}`}
                checked={orderToChange.paymentExpiration === 60}
                onChange={() => setOrderToChange({ ...orderToChange, paymentExpiration: 60 })}
              />
              <label htmlFor={`60-${orderCardContent.description}`}>60</label>
              <input
                type="checkbox"
                id={`90-${orderCardContent.description}`}
                disabled={orderCardContent.status === "CLOSED"}
                checked={orderToChange.paymentExpiration === 90}
                onChange={() => setOrderToChange({ ...orderToChange, paymentExpiration: 90 })}
              />
              <label htmlFor={`90-${orderCardContent.description}`}>90</label>
            </span>
          </span>
        </span>
        <span>
          {/* <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableHeader">İSKONTO TUTARI : </span>
            <span className="orderCardUpdatableValue">
              {Math.round((sumValuesofOrder * (orderToChange.percentages.piecePercentage / 100) + sumValuesofWork * (orderToChange.percentages.workerPercentage / 100)) * 100) /
                100}
            </span>
          </span> */}
          <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableHeader">FATURA TARİH : </span>
            <span className="orderCardUpdatableValue">{orderToChange.bill ? moment(orderToChange.bill.date).format("DD/MM/YYYY") : "---"}</span>
          </span>

          <span className="orderCardUpdatableItem">
            <span className="orderCardUpdatableHeader">ÖDEME TARİHİ : </span>
            <span className="orderCardUpdatableValue">
              {orderToChange.bill ? moment(orderToChange.bill.date).add(orderToChange.paymentExpiration, "days").format("DD/MM/YYYY") : "---"}
            </span>
          </span>
          <span style={{ marginTop: "2rem" }} className="orderCardUpdatableItem">
            <span>TOPLAM : </span>
            <span className="orderCardSumValue">
              {numberWithCommas(
                Math.round(
                  (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) + sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                    ((orderToChange.percentages.KDVPercentage + 100) / 100) *
                    100
                ) / 100
              )}
            </span>
          </span>
        </span>
      </span>
      <span>
        {orderCardContent !== orderToChange ? (
          <div className="orderButtonsWrapper">
            <button
              className="deleteBtn"
              onClick={() => {
                setOrderToChange(orderCardContent);
              }}
            >
              Değişiklikleri Sil
            </button>
            <button
              className="addBtn"
              onClick={() => {
                if (orderToChange.status === "CLOSED") {
                  setWarningPopUp(0);
                } else {
                  updatedCard(orderToChange);
                }
              }}
            >
              Değişiklikleri Kaydet
            </button>
          </div>
        ) : null}
      </span>
    </span>
  );
};
