export const PRODUCT_PATH = "/product";
export const CUSTOMER_PATH = "/customer";
export const WORKORDER_PATH = "/workOrder";
export const CUSTOMER_DETAILS_PATH = "/customerDetails";
export const USER_PATH = "/user";
export const LOGIN_PATH = "/user/login";
export const MACHINE_PATH = "/machine";
export const CURRENCY_PATH = "/currency";
export const STATISTICS_PATH = "/statistics";
export const REFRESH_PATH = "/user/refresh";
export const DEFAULT_WORK_PRICE_PATH = "/defaultWorkPrice";
