import React, { useState } from "react";
import "./DeleteCustomerPopUp.scss";
import { DELETE_CUSTOMER_PASSWORD } from "../../../Constants/ENUMS";

interface IDeleteCustomerPopUp {
  onClick: () => void;
  close: (closed: boolean) => void;
}
export const DeleteCustomerPopUp: React.FC<IDeleteCustomerPopUp> = ({ close, onClick }) => {
  const [pass, setPass] = useState<string>("");

  return (
    <div className="deleteBg">
      <div className="deleteWrapper">
        <h2 className="deleteHeader">Müşteriyi Silmek İçin Şifreyi Giriniz</h2>
        <div className="deleteInputWrapper">
          <input type="password" placeholder="Şifreyi Giriniz..." onChange={(e) => setPass(e.target.value)} />
        </div>

        <div className="buttonWrapper">
          <button
            className="deleteButton"
            disabled={pass !== DELETE_CUSTOMER_PASSWORD}
            onClick={() => {
              onClick();
              close(true);
            }}
          >
            Onayla
          </button>
          <button className="CancelButton" onClick={() => close(true)}>
            İptal
          </button>
        </div>
      </div>
    </div>
  );
};
