import "./EditPaymentPopUp.scss";
import { PaymentEntries } from "../../../Models/PaymentEntries";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

interface IEditPaymentPopUp {
  close: (closed: boolean) => void;
  initPaymentEntry: PaymentEntries;
  editedPaymentEntry: (entry: PaymentEntries) => void;
}

export const EditPaymentPopUp: React.FC<IEditPaymentPopUp> = ({ close, initPaymentEntry, editedPaymentEntry }) => {
  const [paymentEntry, setPaymentEntry] = useState<PaymentEntries>();

  useEffect(() => {
    setPaymentEntry(initPaymentEntry);
  }, [initPaymentEntry]);

  return (
    <div className="AddPopUpBg">
      <div className="AddPopUpPaymentEntryWrapper">
        <div className="AddPopUpHeader">Çıktı Alınacak Fatura</div>

        <div className="AddPopUpContent">
          <div className="billPartInputBtnWrapper">
            <div className="billPartInputWrapper">
              <div>
                <div className="billPartInputHeader">Alınan Ücret:</div>
                <input
                  type="number"
                  step=".01"
                  defaultValue={initPaymentEntry.payedAmount}
                  placeholder="Miktar Giriniz..."
                  onChange={(event) => {
                    if (paymentEntry) {
                      if (!isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) !== 0) {
                        setPaymentEntry({ ...paymentEntry, payedAmount: parseFloat(event.target.value) });
                      } else {
                        setPaymentEntry({ ...paymentEntry, payedAmount: 0 });
                      }
                    }
                  }}
                />
              </div>
              <div>
                <div className="billPartInputHeader">Tarih:</div>
                <DatePicker
                  value={paymentEntry ? moment(paymentEntry.date).format("DD/MM/YYYY") : ""}
                  placeholderText="Tarih Giriniz..."
                  locale={tr}
                  onChange={(date) => {
                    if (paymentEntry) setPaymentEntry({ ...paymentEntry, date: date });
                  }}
                />
              </div>
              <div>
                <div className="billPartInputHeader">Ödeme Şekli:</div>
                <input
                  placeholder="Açıklama Giriniz..."
                  defaultValue={initPaymentEntry.paymentDescription}
                  type="text"
                  onChange={(event) => {
                    if (paymentEntry) setPaymentEntry({ ...paymentEntry, paymentDescription: event.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <span className="buttonWrapper">
          <button
            disabled={paymentEntry && (paymentEntry.payedAmount === 0 || paymentEntry.paymentDescription === "" || paymentEntry.date === null)}
            onClick={() => {
              if (paymentEntry) editedPaymentEntry(paymentEntry);
              close(true);
            }}
            className="addBtn"
          >
            Ücreti Öde
          </button>
          <button
            className="cancelBtn"
            onClick={() => {
              close(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
