import { useCallback, useEffect, useRef, useState } from "react";
import { CustomerWrapper } from "../../Components/CustomerWrapper/CustomerWrapper";
import { Customer } from "../../Models/Customer";
import { Navbar } from "../../Components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/reducers";
import useDefaultWorkPrice from "../../hooks/useDefaultWorkPrice";
import useCustomer from "../../hooks/useCustomer";
import { API_LIMIT } from "../../Constants/ENUMS";

export const Home = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  const { currencygeneral, defaultWorkOrderPrice } = useSelector((state: GlobalState) => ({ currencygeneral: state.currency, defaultWorkOrderPrice: state.defaultWorkPrice }));
  const { priceLoading, updateDefaultWorkPrice } = useDefaultWorkPrice();

  const { customersLoading, searchCustomers, updateCustomer, deleteCustomer, addCustomer } = useCustomer();
  const timerRef = useRef<number | undefined>(undefined);

  const customerSearchDebouncer = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length > 2) {
        if (timerRef.current) window.clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
          searchCustomers(1, API_LIMIT, searchTerm)
            .then((result) => {
              setCustomers(result);
            })
            .catch((err) => console.error(err));
        }, 600);
      }
    },
    [searchCustomers]
  );

  useEffect(() => {
    searchCustomers(1, API_LIMIT, "").then((resultCustomers) => setCustomers(resultCustomers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />

      <CustomerWrapper
        currency={currencygeneral}
        customerSearch={customerSearchDebouncer}
        updateDefaultWorkPrice={updateDefaultWorkPrice}
        hookedDefaultWorkPrice={defaultWorkOrderPrice}
        customers={customers}
        isParentLoading={customersLoading || priceLoading}
        updateCustomer={updateCustomer}
        deleteCustomer={deleteCustomer}
        addCustomer={addCustomer}
      />
    </>
  );
};
