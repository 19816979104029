import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerDetails } from "../../Models/CustomerDetails";
import { OrderPart } from "../../Components/OrderPart/OrderPart";
import classes from "./WorkOrderPage.module.scss";
import { Navbar } from "../../Components/Navbar/Navbar";
import { BillPart } from "../../Components/BillPart/BillPart";
import { ThreeCircles } from "react-loader-spinner";

import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/reducers";

import useWorkOrder from "../../hooks/useWorkOrder";
import useCustomer from "../../hooks/useCustomer";

export const WorkOrderPage = () => {
  const { customerId, partName, workOrderId } = useParams<{ customerId: string; partName: "WorkOrders" | "Bills"; workOrderId: string }>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [openTab, setOpenTab] = useState<"Bills" | "WorkOrders" | undefined>(partName);

  const defaultWorkPrice: number = useSelector((state: GlobalState) => state.defaultWorkPrice);

  const { workOrdersLoading, updateWorkOrder, deleteWorkOrder, addWorkOrder } = useWorkOrder();

  const { customersLoading, getCustomerDetails, updateCustomerDetails } = useCustomer();

  useEffect(() => {
    if (customerId) {
      getCustomerDetails(parseInt(customerId)).then((details) => setCustomerDetails(details));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <>
      <Navbar />
      <div className={classes.workOrderPage}>
        <>
          <span className={classes.workOrderTabs}>
            <input type="checkbox" checked={openTab === "WorkOrders"} id="WorkOrders" onChange={() => setOpenTab("WorkOrders")} />
            <label htmlFor="WorkOrders">İş Emirleri</label>

            <input type="checkbox" checked={openTab === "Bills"} id="Bills" onChange={() => setOpenTab("Bills")} />
            <label htmlFor="Bills">Faturalar</label>
          </span>
          {workOrdersLoading || customersLoading ? (
            <div className={classes.LoaderWrapper}>
              <ThreeCircles height="80" width="80" color="var(--primary-color)" />
            </div>
          ) : null}

          {customerDetails && openTab === "WorkOrders" ? (
            <OrderPart
              handleDeleted={(workOrder) => {
                deleteWorkOrder(workOrder);
                setCustomerDetails({ ...customerDetails, workOrders: customerDetails.workOrders.filter((wO) => wO._id !== workOrder._id) });
              }}
              handleAddedWorkOrder={(workOrder) => {
                addWorkOrder(workOrder, customerDetails._customerId).then((result) => setCustomerDetails(result));
              }}
              handleUpdatedWorkOrder={(workOrder, justNumberSet) => {
                updateWorkOrder(workOrder, justNumberSet).then((result) => setCustomerDetails(result));
              }}
              customerDetails={customerDetails}
              orderToOpen={workOrderId}
              defaultWorkPrice={defaultWorkPrice}
            />
          ) : openTab === "Bills" && customerDetails ? (
            <BillPart
              updatedCustomer={(customerDetails) => {
                updateCustomerDetails(customerDetails);
                setCustomerDetails(customerDetails);
              }}
              customerDetails={customerDetails}
            />
          ) : null}
        </>
      </div>
    </>
  );
};
